import { app } from "..";
import Branch from "../../tsunami/Branch";
import { awaitTimeout } from "../../tsunami/await";

export class MonthSelectedController extends Branch {

    constructor() {
        super();
        app.view.monthSelected.grid.provider = app.model.firstTwelveDrops;
    }

    load() {
        app.view.stage.addChild(app.view.monthSelected);
    }

    show() {
        const promise1 = app.view.monthSelected.show();
        app.view.squareGridWhite.squareGridLines.hideLinesForMonthSelected();
        awaitTimeout(0.5).then(() => {
            app.view.circularLoader.setLoadIndex(app.model.firstTwelveDrops.length, 0.5);
        });
        const promise2 = promise1.then(() => {
            this.waitForNextPage();
        });
        return promise2;
    }

    waitForNextPage() {
        awaitTimeout(5).then(() => {
            this.router.pushState("snkrs/month/week");
        });
    }

    hide() {
        app.view.circularLoader.router.location = "";
        const promise1 = app.view.monthSelected.hide();
        const promise2 = promise1.then(() => {
            app.view.stage.removeChild(app.view.monthSelected);
        });
        return Promise.resolve();
    }

}
