import { awaitTimeout } from "../../tsunami/await";
import Branch from "../../tsunami/Branch";
import { app } from "..";

export default class DropTimeTitleController extends Branch {

    constructor() {
        super();
    }

    show() {
        return app.view.title.showDropTimeTitle(this.slug);
    }

    hide() {
        app.view.title.hideDropTimeTitle(this.slug);
        return awaitTimeout(0.5);
    }

}