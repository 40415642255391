import { PixiContainer } from "../PixiContainer";
import * as PIXI from 'pixi.js';
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Easing from "../../../tsunami/animation/Easing";
import NumberText from "./NumberText";

export default class CircleInfoText extends PixiContainer {

    constructor() {
        super();
        // this.guideText = new PIXI.Text("01 / 20", {
        //     fontFamily: 'NeueHelveticaforHOI',
        //     fontSize: 26,
        //     fill: 0xffffff,
        //     align: 'right'
        // });
        // this.addChild(this.guideText);

        this.countText = new NumberText("00", {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 26,
            fill: 0xffffff,
            align: 'right'
        });
        this.countText.showDelay = 0.25;
        this.addChild(this.countText);

        this.slashText = new PIXI.Text("/", {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 26,
            fill: 0xffffff,
            align: 'right'
        });
        this.slashText.x = 33;
        this.addChild(this.slashText);

        this.totalText = new NumberText("00", {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 26,
            fill: 0xffffff,
            align: 'right'
        });
        this.totalText.showDelay = 0.5;
        this.totalText.x = 48;
        this.addChild(this.totalText);

        const size = { x: 85, y: 30 };
        this.textMask = new PIXI.Graphics();
        this.textMask.beginFill(0xff0000, 1);
        this.textMask.drawRect(0, 0, size.x, size.y);
        // this.textMask.alpha = 0.5;
        this.textMask.x = - size.x;
        this.addChild(this.textMask);
        this.mask = this.textMask;

        this.updateText = this.updateText.bind(this);

        this.showMaskTween = new Tween(0, 0.5, [new TweenProperty(this.textMask, "x", -size.x, 0, Easing.cubic.easeOut)]);

        this.hideMaskTween = new Tween(0, 0.5, [new TweenProperty(this.textMask, "x", 0, -size.x, Easing.cubic.easeIn)]);
    }

    showDelayComplete() {
        super.showDelayComplete();
        const promise1 = this.showMaskTween.start();
        const promise2 = this.countText.show();
        const promise3 = this.totalText.show();
        return Promise.all([promise1, promise2, promise3]);
    }

    hideDelayComplete() {
        const promise1 = this.hideMaskTween.start();
        const promise2 = this.countText.hide();
        const promise3 = this.totalText.hide();
        return Promise.all([promise1, promise2, promise3]);
    }

    updateText() {
        // this.text.text = addLeadingZero(this.count) + " / " + addLeadingZero(this.total);
        if (this.isVisible) window.requestAnimationFrame(this.updateText);
    }

}