import * as PIXI from 'pixi.js';
import Easing from '../../../tsunami/animation/Easing';
import Tween from '../../../tsunami/animation/Tween';
import TweenProperty from '../../../tsunami/animation/TweenProperty';
import { addLeadingZero } from '../../../tsunami/utils/number';
import { PixiContainer } from '../PixiContainer';

export default class NumberText extends PixiContainer {

    constructor(text, style) {
        super();
        this._number = 0;
        this.textField = new PIXI.Text(text, style);
        this.addChild(this.textField);
    }

    get number() {
        return this._number;
    }

    set number(value) {
        this._number = value;
    }

    showDelayComplete() {
        super.showDelayComplete();
        return new Tween(0, 0.5, [new TweenProperty(this, "text", 0, this.number, Easing.cubic.easeOut)]).start();
    }

    hideDelayComplete() {
        return new Tween(0, 0.5, [new TweenProperty(this, "text", this.number, 0, Easing.cubic.easeOut)]).start();
    }

    tweenTo(value, duration = 0) {
        const oldValue = this.number;
        this.number = value;
        if (duration > 0) {
            return new Tween(0, duration, [new TweenProperty(this, "text", oldValue, this.number, Easing.cubic.easeOut)]).start();
        } else {
            this.text = value;
        }
    }

    set text(value) {
        this.textField.text = addLeadingZero(Math.round(value));
    }
}