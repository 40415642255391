import Branch from "../../tsunami/Branch";
import { app } from "..";

export default class LoopController extends Branch {

    constructor() {
        super();
    }

    show() {
        app.controller.router.pushState("");
    }

}