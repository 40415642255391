import { PixiContainer } from "../PixiContainer";
import * as PIXI from 'pixi.js';
import { globalTextures } from "../GlobalAssets";
import NikeSprite from "../NikeSprite";
import HOITextNike from "./HOITextNike";
import HOITextHouseOf from "./HOITextHouseOf";
import HOILocationText from "./HOILocationText";

export default class HOILogo extends PixiContainer {

    constructor() {
        super();

        this.guide = new PIXI.Sprite(globalTextures.hoi);
        this.guide.alpha = 0.5;
        // this.addChild(this.guide);

        this.nikeLogo = new NikeSprite(globalTextures.nike.white);
        this.nikeLogo.scale.x = this.nikeLogo.scale.y = 1;
        this.nikeLogo.x = 44;
        this.nikeLogo.y = -1;
        this.nikeLogo.showDelay = 0.5;
        this.addChild(this.nikeLogo);

        const style = {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 16.5,
            fill: 0xffffff,
            align: 'left',
        }

        this.nikeText = new HOITextNike(style);
        this.nikeText.y = - 3;
        this.addChild(this.nikeText);

        this.hoiText = new HOITextHouseOf(style);
        this.hoiText.y = this.nikeText.y + style.fontSize;
        this.addChild(this.hoiText);

        this.locationText = new HOILocationText(style);
        this.locationText.y = this.hoiText.y + style.fontSize;
        this.addChild(this.locationText);
    }

    showDelayComplete() {
        super.showDelayComplete();
        const promises = [
            this.nikeLogo.show(),
            this.nikeText.show(),
            this.hoiText.show(),
            this.locationText.show(),
        ];
        return Promise.all(promises);
    }

    hideDelayComplete() {
        const promises = [
            this.nikeLogo.hide(),
            this.nikeText.hide(),
            this.hoiText.hide(),
            this.locationText.hide(),
        ];
        return Promise.all(promises);
    }

}