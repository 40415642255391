import { app } from "..";
import Branch from "../../tsunami/Branch";
import { awaitTimeout } from "../../tsunami/await";

export class MonthAllController extends Branch {

    constructor() {
        super();

        app.view.monthAll.grid.provider = app.model.drops.value;
    }

    load() {
        app.view.stage.addChild(app.view.monthAll);
    }

    show() {
        app.view.monthAll.show();
        const promise1 = awaitTimeout(1.25);
        const promise2 = promise1.then(() => {
            app.view.title.dropTimeTitleRouter.location = (app.model.inStockMode) ? "inStock" : "month";
            return awaitTimeout(0.75);
        });
        const promise3 = promise2.then(() => {
            if (app.model.drops.value.length > 0) {
                app.view.circularLoader.router.location = app.model.drops.value.length + "-month/12-month";
            }
            app.view.circularLoader.setLoadIndex(app.model.drops.value.length);
            this.waitForNextPage();
        });
        return promise3;
    }

    waitForNextPage() {
        if (app.model.drops.value.length > 0) {
            awaitTimeout(5).then(() => {
                this.router.pushState("snkrs/month/selected");
            });
        }
    }

    hide() {
        const promise1 = app.view.monthAll.hide();
        const promise2 = promise1.then(() => {
            app.view.stage.removeChild(app.view.monthAll);
        });
        const promise3 = awaitTimeout(1.0 + 0.025 * app.model.drops.value.length);
        return promise3;
    }

}