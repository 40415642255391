import { app } from "..";
import MonthController from "./MonthController";
import Branch from "../../tsunami/Branch";
import { addLeadingZero } from '../../tsunami/utils/number';
import { addDays, dayMonthFormatter, daysBetween } from "../../tsunami/utils/date";
import { awaitTimeout } from "../../tsunami/await";

export default class SNKRSController extends Branch {

    constructor() {
        super();
        this.branches.month = new MonthController();
        this.defaultChild = "month";

        this.setTitlesModel();
    }

    setTitlesModel() {
        const todayDate = app.model.nowDate;
        const day = addLeadingZero(todayDate.getDate());
        const month = addLeadingZero(todayDate.getMonth() + 1);

        let nextMonthIndex = todayDate.getMonth() + 1;
        if (nextMonthIndex >= 12) nextMonthIndex -= 12;
        const nextMonthDate = app.model.nowDate;
        nextMonthDate.setMonth(nextMonthIndex);
        const nextMonthDay = addLeadingZero(nextMonthDate.getDate());
        const nextMonthMonth = addLeadingZero(nextMonthDate.getMonth() + 1);

        const nextWeekDate = addDays(app.model.nowDate, 7);
        const nextWeekDay = addLeadingZero(nextWeekDate.getDate());
        const nextWeekMonth = addLeadingZero(nextWeekDate.getMonth() + 1);

		const dateFull = dayMonthFormatter(day, month, app.model.city.dateFormat);
		const nextMonthDateFull = dayMonthFormatter(nextMonthDay, nextMonthMonth, app.model.city.dateFormat);
		const nextWeekDateFull = dayMonthFormatter(nextWeekDay, nextWeekMonth, app.model.city.dateFormat);

        const titlesModel = {
            title: "SNKRS",
            timeTitles: {
                month: {
                    dropString: app.model.copydeck.dropping_this_month.toUpperCase(),
                    dateString: `/ ${dateFull} – ${nextMonthDateFull} /`
                },
                week: {
                    dropString: app.model.copydeck.dropping_this_week.toUpperCase(),
                    dateString: `/ ${dateFull} – ${nextWeekDateFull} /`
                },
                today: {
                    dropString: app.model.copydeck.dropping_today.toUpperCase(),
                    dateString: `/ ${dateFull} /`
                },
                inStock: {
                    dropString: app.model.copydeck.in_stock_title_line_1.toUpperCase(),
                    dateString: app.model.copydeck.in_stock_title_line_2.toUpperCase()
                }
            }
        }
        app.view.title.model = titlesModel;
    }

    load() {
    }

    show() {
        // console.log("AppController.show");
        const promise1 = app.view.squareGridWhite.show();
        const promise2 = promise1.then(() => {
            return app.view.title.show();
        });

        app.view.stage.addChild(app.view.hoiLogos.element);
        const promise3 = app.view.hoiLogos.show();
        const promise4 = promise3.then(() => {
            app.view.stage.removeChild(app.view.hoiLogos.element);
        })
        return awaitTimeout(2.5);
    }

    hide() {
        app.view.circularLoader.router.location = "";
        app.view.title.dropTimeTitleRouter.location = "";
        app.view.squareGridWhite.squareGridLines.hideAll();
        return app.view.title.hide();
    }

}
