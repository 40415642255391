import Easing from '../../../tsunami/animation/Easing';
import Tween from '../../../tsunami/animation/Tween';
import TweenProperty from '../../../tsunami/animation/TweenProperty';
import Point from '../../../tsunami/geom/Point';
import CellHighlights from '../CellHighlights';

export default class MonthSelectedCellHighlights extends CellHighlights {

    constructor(gridSize, gridSpan) {
        super(gridSize, gridSpan, new Point(0.33, 1));
        this.showSetup = [
            { cells: [1, 1, 1], delay: 0 },
            { cells: [1, 1, 1], delay: 1 },
            { cells: [1, 1, 1], delay: 2 },
            { cells: [1, 1, 1], delay: 3 },
        ];

        const rowDelay = 0.3;

        this.rows.forEach((row, i) => {
            const showData = this.showSetup[i];
            this.showTimeline.add(new Tween(showData.delay / 3 * rowDelay, 0.33, [new TweenProperty(row, "x", -gridSize.x, gridSize.x, Easing.linear.ease)]));
        });
    }

}
