const cities = [
    {
        name: "PAR",
        index: "002",
        language: "fr",
        marketplace: "FR",
		logo: 'par-logo.png',
		use24HourTime: true,
		dateFormat: 'ddmm',
    },
    {
        name: "NYC",
        index: "000",
        language: "en",
        marketplace: "US",
		use24HourTime: false,
		dateFormat: 'mmdd',
    },
    {
        name: "SHG",
        index: "003",
        language: "en",
        marketplace: "CH",
		use24HourTime: false,
		dateFormat: 'mmdd',
    }
];

const localization = {
    en: {
        dropping_this_month: "Dropping This Month",
        dropping_today: "Dropping Today",
        dropping_this_week: "Dropping This Week",
        in_stock_title_line_1: "RECENT DROPS",
        in_stock_title_line_2: "IN STOCK /",
        loading_more: "Loading More SNKRS",
        next_up: "Next Up",
        today: "Today",
        next_drop_in: "Next Drop In"
    },
    fr: {
        dropping_this_month: "Sorties du mois",
        dropping_today: "Sorties aujourd’hui",
        dropping_this_week: "Sorties de la semaine",
        in_stock_title_line_1: "SORTIES RÉCENTES",
        in_stock_title_line_2: "EN STOCK / ",
        loading_more: "Chargement en cours SNKRS",
        next_up: "À venir",
        today: "Aujourd’hui",
        next_drop_in: "Prochaines sorties"
    }

};


module.exports = { cities, localization }
