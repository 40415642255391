import { app } from "..";
import Branch from "../../tsunami/Branch";
import { awaitTimeout } from "../../tsunami/await";

export class Month12Controller extends Branch {

    constructor() {
        super();
        app.view.monthSelected.grid.provider = app.model.drops;
    }

    load() {
        app.view.stage.addChild(app.view.monthSelected);
    }

    show() {
        const promise1 = app.view.monthSelected.show();
        app.view.squareGridWhite.squareGridLines.hideLinesForMonthSelected();
        // awaitTimeout(0.5).then(() => {
        //     app.view.circularLoader.setLoadIndex(app.model.firstTwelveDrops.length, 0.5);
        // });
        const promise2 = promise1.then(() => {
            app.view.title.dropTimeTitleRouter.location = (app.model.inStockMode) ? "inStock" : "month";
            return awaitTimeout(0.75);
        });
        const promise3 = promise2.then(() => {
            if (app.model.drops.value.length > 0) {
                app.view.circularLoader.router.location = app.model.drops.value.length + "-month/5-month";
            }
            app.view.circularLoader.setLoadIndex(app.model.drops.value.length);
            this.waitForNextPage();
        });
        return promise3;
    }

    waitForNextPage() {
        if (app.model.drops.value.length > 0) {
            awaitTimeout(7).then(() => {
                this.router.pushState("snkrs/month/week");
            });
        }
    }

    hide() {
        app.view.circularLoader.router.location = "";
        const promise1 = app.view.monthSelected.hide();
        const promise2 = promise1.then(() => {
            app.view.stage.removeChild(app.view.monthSelected);
        });
        return Promise.resolve();
    }

}
