import * as PIXI from 'pixi.js';
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Easing from "../../../tsunami/animation/Easing";
import { appConfig } from '../../AppConfig';
import { SquareGridLines } from './SquareGridLines';

export default class SquareGrid extends PIXI.Container {

    constructor(square_grid) {
        super();

        // const squareGridWhite = new PIXI.Sprite(square_grid);
        // squareGridWhite.x = 0;
        // squareGridWhite.y = appConfig.titleHeight;
        // squareGridWhite.width = appConfig.width;
        // squareGridWhite.height = appConfig.height - appConfig.titleHeight;
        // this.addChild(squareGridWhite);
        this.squareGridLines = new SquareGridLines();
        this.squareGridLines.x = 0;
        this.squareGridLines.y = appConfig.titleHeight;
        this.addChild(this.squareGridLines);

        this.mask = new PIXI.Container();
        this.addChild(this.mask);

        this.edge = 718.5;

        let shape = new PIXI.Graphics();
        shape.x = -this.edge;
        shape.y = 0;
        shape.lineStyle(0);
        shape.beginFill(0xff0000, 1);
        shape.moveTo(0, 0);
        shape.lineTo(this.edge, 0);
        shape.lineTo(this.edge, appConfig.height);
        shape.lineTo(0, appConfig.height);
        this.leftMask = shape;
        this.mask.addChild(this.leftMask);

        shape = new PIXI.Graphics();
        shape.x = 0;
        shape.y = appConfig.height;
        shape.lineStyle(0);
        shape.beginFill(0xff0000, 1);
        shape.moveTo(this.edge, 0);
        shape.lineTo(appConfig.width, 0);
        shape.lineTo(appConfig.width, appConfig.height);
        shape.lineTo(this.edge, appConfig.height);
        this.bottomMask = shape;
        this.mask.addChild(this.bottomMask);
    }

    show() {
        this.squareGridLines.resetAllLines();
        this.leftMaskTween = new Tween(1, 0.33, [new TweenProperty(this.leftMask, "x", -this.edge, 0, Easing.cubic.easeIn)]);
        this.bottomMaskTween = new Tween(1.33, 0.33, [new TweenProperty(this.bottomMask, "y", appConfig.height, 0, Easing.cubic.easeIn)]);
        return Promise.all([
            this.leftMaskTween.start(),
            this.bottomMaskTween.start(),
        ]);
    }

}