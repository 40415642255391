import * as PIXI from 'pixi.js';
import { appConfig } from "../../AppConfig";

export default class CircleShape extends PIXI.Graphics {

    constructor(color = 0xffffff) {
        super();
        this.lineStyle(0);
        this.beginFill(color, 1);
        this.drawCircle(appConfig.width / 2, appConfig.width / 2, appConfig.productCircleRadius);
    }

}
