import * as PIXI from 'pixi.js';
import {prepareAssetPath} from "../../../../../../../utilities";

export function loadPixiSpriteSheet(rawUrl) {
	const url = prepareAssetPath(rawUrl);
	const promise = new Promise((success, reject) => {
		const onAssetsLoaded = (loader, resources) => {
			console.log(PIXI.Loader.shared.resources[url])
			success(PIXI.Loader.shared.resources[url].spritesheet);
		}
		PIXI.Loader.shared.add(url).load(onAssetsLoaded);
	});

	promise.progress = 0;

	return promise;
}

export function setPixiSpriteSheet(url, data) {
	const imgUrl = prepareAssetPath(url);
	data.meta.image = imgUrl;

	const promise = new Promise((success, reject) => {
		PIXI.Loader.shared.add(imgUrl).load((resource) => {
			if (resource.error) {
				console.error(resource.error);

			} else {
				const texture = PIXI.Loader.shared.resources[imgUrl].texture.baseTexture;
				const sheet = new PIXI.Spritesheet(texture, data);
				sheet.parse((...args) => {
					success(sheet);
				})
			}
		});
	});

	promise.progress = 0;

	return promise;
}
