import UIListBase from "../../../tsunami/components/UIListBase";
import { PixiContainer } from "../PixiContainer";
import * as PIXI from 'pixi.js';
import { globalTextures } from "../GlobalAssets";
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Easing from "../../../tsunami/animation/Easing";
import { PixiListContainer } from "../PixiListContainer";
import Point from "../../../tsunami/geom/Point";
import Scope from "../../../tsunami/Scope";
import Rectangle from "../../../tsunami/geom/Rectangle";

const size = new Rectangle(-5, 35, 710, 170);

export default class Cropmarks extends UIListBase {

    constructor() {
        const element = new PixiListContainer();
        super(element);
        this.alsoShowChildren = true;


        this.provider = [
            { position: new Point(size.x, size.y) },
            { position: new Point(size.x + size.width, size.y) },
            { position: new Point(size.x + size.width, size.y + size.height) },
            { position: new Point(size.x, size.y + size.height) },
        ];
    }

    destroyChild(element) {
        element.destroy();
    }

    _createElement(data, index, length) {
        const scope = new Scope(data, this.scope, index, length);
        const cell = new CropMark(scope);
        return cell;
    }

}

export class CropMark extends PixiContainer {

    constructor(scope) {
        super();
        this.scope = scope;
        this.sprite = new PIXI.Sprite(globalTextures.cropmark);
        this.sprite.x = -10.5;
        this.sprite.y = -10.5;
        this.sprite.width = 21;
        this.sprite.height = 21;
        this.addChild(this.sprite);
        this.showRotateTween = new Tween(0, 0.66, [new TweenProperty(this, "rotation", 0, Math.PI * 2, Easing.cubic.easeOut)]);
        this.showMoveTween = new Tween(0, 0.66, [
            new TweenProperty(this, "x", 0, this.scope.data.position.x, Easing.cubic.easeOut),
            new TweenProperty(this, "y", 0, this.scope.data.position.y, Easing.cubic.easeOut),
        ]);
        this.sprite.alpha = 0;
        this.x = size.x;
        this.y = size.y;
    }

    show() {
        this.sprite.alpha = 1;
        const promise1 = Promise.all([
            this.showRotateTween.start(),
            this.showMoveTween.start(),
        ]);
        const promise2 = promise1.then(() => {
            return this.showRotateTween.start();
        });
        return promise2;
    }

}
