import * as PIXI from 'pixi.js';
import Tween from "../../../tsunami/animation/Tween";
import Timeline from "../../../tsunami/animation/Timeline";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Easing from "../../../tsunami/animation/Easing";
import { appConfig } from "../../AppConfig";

export default class DropTimeTitle extends PIXI.Container {

    constructor(delay, startX) {
        super();
        this.alpha = 0;

        this.delay = delay;
        this.dropText = new PIXI.Text("test", {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 60,
            fill: 0xffffff,
            align: 'left',
        });
        this.addChild(this.dropText);

        this.dateText = new PIXI.Text("test", {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 60,
            fill: 0xffffff,
            align: 'left',
        });
        this.dateText.y = 60;
        this.addChild(this.dateText);

        this.showTimeline = new Timeline();
        this.showTimeline.add(new Tween(delay, 0.5, [new TweenProperty(this.dropText, "x", startX, 0, Easing.cubic.easeOut)]));
        this.showTimeline.add(new Tween(delay + 0.33, 0.5, [new TweenProperty(this.dateText, "x", startX, 0, Easing.cubic.easeOut)]));

        this.hideTimeline = new Timeline();
        this.hideTimeline.add(new Tween(delay, 0.5, [new TweenProperty(this.dropText, "x", 0, -appConfig.width + startX, Easing.cubic.easeOut)]));
        this.hideTimeline.add(new Tween(delay + 0.33, 0.5, [new TweenProperty(this.dateText, "x", 0, -appConfig.width + startX, Easing.cubic.easeOut)]));
    }

    set model(value) {
        this.dropText.text = value.dropString.toUpperCase();
        this.dateText.text = value.dateString;
    }

    show() {
        this.alpha = 1;
        return this.showTimeline.start();
    }

    hide() {
        return this.hideTimeline.start().then(() => {
            this.alpha = 0;
        });
    }

}
