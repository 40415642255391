import * as PIXI from 'pixi.js';

export class PixiListContainer extends PIXI.Container {

    constructor() {
        super();
    }

    appendChild(child) {
        this.addChild(child);
    }

    insertBefore(child, ref) {
        console.log("insertBefore", child, ref);
        this.addChild(child);
    }

}

