import Easing from '../../../tsunami/animation/Easing';
import Tween from '../../../tsunami/animation/Tween';
import TweenProperty from '../../../tsunami/animation/TweenProperty';
import CellHighlights from '../CellHighlights';

export default class MonthAllCellHighlights extends CellHighlights {

    constructor(gridSize, gridSpan) {
        super(gridSize, gridSpan);
        this.showSetup = [
            { cells: [0, 0, 1, 1, 1, 0], delay: 0 },
            { cells: [1, 0, 0, 0, 0, 0], delay: 1 },
            { cells: [0, 1, 0, 1, 0, 0], delay: 3 },
            { cells: [0, 0, 1, 0, 1, 1], delay: 4 },
            { cells: [0, 0, 0, 0, 1, 0], delay: 5 },
            { cells: [1, 0, 1, 1, 0, 1], delay: 0 },
            { cells: [0, 1, 0, 0, 0, 0], delay: 1 },
            { cells: [0, 0, 0, 1, 0, 0], delay: 3 }
        ];
        this.hideSetup = [
            { cells: [1, 1, 1, 1, 1, 1], delay: 0 },
            { cells: [1, 1, 1, 1, 1, 1], delay: 1 },
            { cells: [1, 0, 0, 0, 0, 0], delay: 2 },
            { cells: [0, 0, 0, 0, 0, 0], delay: 0 },
            { cells: [0, 0, 0, 0, 0, 0], delay: 0 },
            { cells: [0, 0, 0, 0, 0, 0], delay: 0 },
            { cells: [0, 0, 0, 0, 0, 0], delay: 0 },
            { cells: [0, 0, 0, 0, 0, 0], delay: 0 },
        ];

        const rowDelay = 0.3;

        this.rows.forEach((row, i) => {
            const showData = this.showSetup[i];
            const hideData = this.hideSetup[i];
            this.showTimeline.add(new Tween(showData.delay / 5 * rowDelay, 0.33, [new TweenProperty(row, "x", -gridSize.x, gridSize.x, Easing.linear.ease)]));
            this.hideTimeline.add(new Tween(hideData.delay / 2 * rowDelay, 0.5, [new TweenProperty(row, "x", -gridSize.x, gridSize.x, Easing.linear.ease)]));
            this.hideTimeline.add(new Tween(hideData.delay / 2 * rowDelay + 0.5, 0.5, [new TweenProperty(row, "x", -gridSize.x, gridSize.x, Easing.linear.ease)]));
        });
    }

}
