import * as PIXI from 'pixi.js';
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Easing from "../../../tsunami/animation/Easing";
import { appConfig } from '../../AppConfig';
import { PixiContainer } from '../PixiContainer';
import Timeline from '../../../tsunami/animation/Timeline';
import Cropmarks from './Cropmarks';
import { globalSpriteSheets } from '../GlobalAssets';
import AnimatedSpriteContainer from '../AnimatedSpriteContainer';
import Rectangle from '../../../tsunami/geom/Rectangle';

export default class TopTitleSprite extends PixiContainer {

    constructor(index) {
        super();
        this.index = index;

        this.pixiText = new PIXI.Text("PIXI Text", {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 200,
            fill: 0xffffff,
            align: 'left'
        });
        this.addChild(this.pixiText);

        this.cropmarks = new Cropmarks();
        this.cropmarks.showDelay = 1;
        this.addChild(this.cropmarks.element);

        this.states = {
            start: {
                position: { x: 102, y: 1300 },
                scale: { x: 2.5, y: 2.5 }
            },
            end: {
                position: { x: 25, y: 50 },
                scale: { x: 1, y: 1 }
            }
        }

        let selectedState = this.states.start;
        this.x = selectedState.position.x + 1000;
        this.y = selectedState.position.y;
        this.scale.x = selectedState.scale.x;
        this.scale.y = selectedState.scale.y;

        this.showTimeline = new Timeline();
        this.showTimeline.add(new Tween(0, 0.66, [
            new TweenProperty(this, "x", this.states.start.position.x + 1000, this.states.start.position.x, Easing.cubic.easeOut),
            new TweenProperty(this, "y", this.states.start.position.y, this.states.start.position.y, Easing.cubic.easeOut)
        ]));

        this.showTimeline.add(new Tween(0.66, 0.5, [
            new TweenProperty(this, "x", this.states.start.position.x, this.states.end.position.x, Easing.cubic.easeOut),
            new TweenProperty(this, "y", this.states.start.position.y, this.states.end.position.y, Easing.cubic.easeOut),
            new TweenProperty(this.scale, "x", this.states.start.scale.x, this.states.end.scale.x, Easing.cubic.easeOut),
            new TweenProperty(this.scale, "y", this.states.start.scale.y, this.states.end.scale.y, Easing.cubic.easeOut),
        ]));

        this.hideTimeline = new Timeline();
        this.hideTimeline.add(new Tween(0, 0.66, [
            new TweenProperty(this, "x", this.states.end.position.x, -appConfig.width, Easing.cubic.easeIn)
        ]));

        const barSize = new Rectangle(-5, 22, 710, 170);


        this.bar1 = new AnimatedSpriteContainer(globalSpriteSheets.bars.animations["bar1"], 0.5, false);
        this.bar1.x = 350;
        this.bar1.y = 35;
        this.bar1.showDelay = 1.25;
        this.addChild(this.bar1);

        this.bar2 = new AnimatedSpriteContainer(globalSpriteSheets.bars.animations["bar2"], 0.5);
        this.bar2.x = 350;
        this.bar2.y = 35 + 170;
        this.bar2.showDelay = 1.75;
        this.addChild(this.bar2);

        this.bar3 = new AnimatedSpriteContainer(globalSpriteSheets.bars.animations["bar3"], 0.5);
        this.bar3.x = -5;
        this.bar3.y = 120;
        this.bar3.showDelay = 1.25;
        this.addChild(this.bar3);

        this.bar4 = new AnimatedSpriteContainer(globalSpriteSheets.bars.animations["bar4"], 0.5);
        this.bar4.x = 707;
        this.bar4.y = 120;
        this.bar4.showDelay = 2;
        this.addChild(this.bar4);
    }

    get text() {
        return this.pixiText.text;
    }

    set text(value) {
        this.pixiText.text = value;
    }

    showDelayComplete() {
        super.showDelayComplete();
        this.bar1.show(this.index == 0);
        this.bar2.show();
        this.bar3.show();
        this.bar4.show();
        return Promise.all([
            this.showTimeline.start(),
            this.cropmarks.show(),
        ]);
    }

    hideDelayComplete() {
        return Promise.all([
            this.hideTimeline.start(),
            this.cropmarks.hide()
        ]);
    }

}
