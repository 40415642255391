import { PixiContainer } from "../PixiContainer";
import HOILogo from "./HOILogo";

export default class HOILogosGridCell extends PixiContainer {

    constructor(scope, size) {
        super();
        this.scope = scope;
        this.size = size;

        this.logo = new HOILogo();
        this.logo.scale.x = this.logo.scale.y = 0.9;
        this.logo.showDelay = scope.data.delay / 2 * 0.5;

        this.addChild(this.logo);
    }

    showDelayComplete() {
        super.showDelayComplete();
        const promise1 = this.logo.show();
        const promise2 = promise1.then(() => {
            return this.logo.hide();
        });
        return promise2;
    }

}