import * as PIXI from 'pixi.js';
import { appConfig } from '../AppConfig';
import Background from './background/Background';
import SquareGrid from './background/SquareGrid';
import TopTitle from './topTitle/TopTitle';
import CircularLoader from './circular-loader/CircularLoader';
import MonthAllView from './month/MonthAllView';
import MonthSelectedView from './month/MonthSelectedView';
import WeekView from './week/WeekView';
import DropProductView from './week/DropProductView';
import HOILogosGrid from './hoi/HOILogosGrid';
import NextDropAnim from './next-drop-anim/NextDropAnim';

export default class AppView extends PIXI.Application {

    constructor({ containerId } = {}) {
        super({
            width: 1080,
            height: 1920,
            backgroundColor: 0x000000,
            resolution: window.devicePixelRatio,
            antialias: true
        });

        this.view.id = 'snkrs-calendar-canvas';

        if (containerId) {
            this.container = document.getElementById(containerId);
            this.container.appendChild(this.view);
        } else {
            document.body.appendChild(this.view);
        }

        const squareGridTexture = PIXI.Texture.from(require('../../../static/assets/images/square_grid_white.png'));

        this.background = new Background(squareGridTexture);
        this.stage.addChild(this.background);

        this.squareGridWhite = new SquareGrid(squareGridTexture);
        this.stage.addChild(this.squareGridWhite);

        this.hoiLogos = new HOILogosGrid();
        this.hoiLogos.element.y = appConfig.titleHeight;
        this.hoiLogos.showDelay = 0.75;

        this.title = new TopTitle();
        this.stage.addChild(this.title);

        this.monthAll = new MonthAllView();
        this.monthAll.y = appConfig.titleHeight;

        this.monthSelected = new MonthSelectedView();
        this.monthSelected.y = appConfig.titleHeight;

        this.dropProductView = new DropProductView();

        this.week = new WeekView();

        this.circularLoader = new CircularLoader();
        this.circularLoader.x = 900;
        this.circularLoader.y = 200;
        this.stage.addChild(this.circularLoader);

        this.nextDropAnim = new NextDropAnim();
        this.stage.addChild(this.nextDropAnim);

        // this.ticker.add((delta) => {

        // });

        this.destroy = () => {
            try {
                document.body.removeChild(this.view);
            } catch (error) { }

            try {
                this.container.removeChild(this.view);
            } catch (error) { }
        }
    }



}
