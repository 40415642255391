import Branch from "../../../../tsunami/Branch";
import CircularLoaderSectionController from "./CircularLoaderSectionController";

export default class CircularLoaderController extends Branch {

    constructor() {
        super();
        this.branches["*"] = new CircularLoaderSectionController();
    }

}