import * as PIXI from 'pixi.js';
import { appConfig } from "../../AppConfig";
import Easing from "../../../tsunami/animation/Easing";
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import UIListBase from '../../../tsunami/components/UIListBase';
import Point from '../../../tsunami/geom/Point';
import Rectangle from '../../../tsunami/geom/Rectangle';
import { PixiListContainer } from '../PixiListContainer';
import { PixiContainer } from '../PixiContainer';

export default class DropViewImages extends UIListBase {

    constructor() {
        const element = new PixiListContainer();
        super(element);
        this.cellSize = new Point(appConfig.width, appConfig.width);
        this.cellRect = new Rectangle(0, 0, appConfig.width, appConfig.width);
        this.cellRectScaled = new Rectangle(0, 0, this.cellRect.width * appConfig.nikeImageFillScale, this.cellRect.height * appConfig.nikeImageFillScale);
    }

    destroyChild(element) {
        element.destroy();
    }

    get provider() {
        return super.provider;
    }

    set provider(value) {
        super.provider = value;
        this.element.x = 0;
    }

    gotoImage(index) {
        const newX = (appConfig.productCircleRadius * -2) * index;
        const tween = new Tween(0, 0.5, [new TweenProperty(this.element, "x", this.element.x, newX, Easing.cubic.easeInOut)]);
        return tween.start();
    }

    _createElement(data, index, length) {
        const spriteContainer = new PixiContainer();
        spriteContainer.x = index * (appConfig.productCircleRadius * 2);

        const dropImage = data;
        const imageRect = new Rectangle(0, 0, dropImage.image.naturalWidth, dropImage.image.naturalHeight);
        const spriteScale = imageRect.getScaleToFitRect(this.cellRectScaled);
        const sprite = new PIXI.Sprite(dropImage.texture);
        sprite.pivot.x = 0;
        sprite.pivot.y = 0;
        sprite.scale.x = spriteScale;
        sprite.scale.y = spriteScale;
        sprite.x = appConfig.width / 2 - sprite.width / 2;
        sprite.y = appConfig.width / 2 - sprite.height / 2;
        // sprite.x = appConfig.productCircleRadius - sprite.width / 2;
        // sprite.y = appConfig.productCircleRadius - sprite.height / 2;
        spriteContainer.addChild(sprite);

        return spriteContainer;
    }

}