import Branch from "../../tsunami/Branch";
import Router from "../../tsunami/Router";
import DropTimeTitleController from "./DropTimeTitleController";

export default class DropTimeTitleRouter extends Router {

    constructor() {
        super(new Branch());
        this.root.branches["month"] = new DropTimeTitleController();
        this.root.branches["week"] = new DropTimeTitleController();
        this.root.branches["today"] = new DropTimeTitleController();
        this.root.branches["inStock"] = new DropTimeTitleController();
    }

}