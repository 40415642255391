import AppController from "./snkrs_cal/controller/AppController";
import AppModel from "./snkrs_cal/model/AppModel";
import AppView from "./snkrs_cal/view/AppView";
import { loadJSON } from "./tsunami/load/loadJSON";
import { Drops } from "./snkrs_cal/model/Drop";
import { loadAssets, loadImages } from "./snkrs_cal/view/GlobalAssets";
import { prepareFontForCanvas } from "./tsunami/utils/canvas";
import { getSearchParams } from "./tsunami/window";
import { cities, localization } from './config';

import { app, setApp } from "./snkrs_cal";
import {fetchDrops} from "./dataService";

class Calendar {
    constructor(options = { logger: console }) {
        const { logger } = options;

        this.logger = logger;
        if (!logger.debug) {
            this.logger.debug = logger.log;
        }

        this.init = ({ divId, data }) => {
            // remove any memory reference
            const cloned = JSON.parse(JSON.stringify(data));

            this.logger.debug('WebGL.CalendarAnimation.init() started', { _t: this, divId, data: cloned })

            if (app.view) {
                app.view.destroy()
                // setApp({});
            }

            app.debug = (data.debug == true);
            app.model = new AppModel();
            app.model.cityCode = data.city || "nyc";
			app.model.topBarY = data.topBarY || 0;
            // Add this line to hardcode city
            // app.model.cityCode = "par";

            const searchParams = getSearchParams(window.location.href);

            prepareFontForCanvas("NeueHelveticaforHOI");

            app.model.cities = cities;
            app.model.city = cities.find((city) => {
                return city.name.toLowerCase() == app.model.cityCode.toLowerCase();
            });

            const calendarPromise = fetchDrops({
                inStock: searchParams.inStock,
                marketplace: app.model.city.marketplace,
                language: app.model.city.language,
                onComplete: ({drops, inStockMode}) => {
                    console.log({drops, inStockMode})
                    app.model.drops.value = drops;
                    app.model.inStockMode = inStockMode;
                }
            });

            app.model.copydeck = localization[app.model.city.language] || localization.en;

            const texturesPromise = loadAssets();

            Promise.all([calendarPromise, texturesPromise]).then(() => {
                app.view = new AppView({ containerId: divId });
                app.controller = new AppController();
                app.controller.router.start();

            });

            this.logger.debug('WebGL.CalendarAnimation.init() finished', { _t: this, divId, data })
        }

        this.start = () => {
            this.logger.debug('WebGL.CalendarAnimation.start()', { _t: this });
        }

        this.pause = () => {
            this.logger.debug('WebGL.CalendarAnimation.pause()', { _t: this });
        }

        this.reset = () => {
            this.logger.debug('WebGL.CalendarAnimation.reset()', { _t: this });
        }

        this.destroy = () => {
            this.logger.debug('WebGL.CalendarAnimation.destroy()', { _t: this });

            if (app.view) {
                app.view.destroy()
                // setApp({});
            }
        }
    }

}

(() => { window.CalendarAnimation = Calendar; })();
