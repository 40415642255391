import * as PIXI from 'pixi.js';
import { appConfig } from "../../AppConfig";
import DropViewInfoContainer from "./DropViewInfoContainer";
import DropViewDetailsContainer from "./DropViewDetailsContainer";
import DropViewWhiteCircle from "./DropViewWhiteCircle";
import DropViewImagesContainer from "./DropViewImagesContainer";
import MonthAllCellHighlights from '../month/MonthAllCellHighlights';
import Point from '../../../tsunami/geom/Point';

export default class DropProductView extends PIXI.Container {

    constructor() {
        super();
        this.info = new DropViewInfoContainer();
        // this.addChild(this.info);

        this.details = new DropViewDetailsContainer();
        // this.addChild(this.details);

        this.whiteCircle = new DropViewWhiteCircle();
        this.whiteCircle.x = appConfig.width / 2;
        // this.whiteCircle.y = appConfig.width / 2;
        // this.addChild(this.whiteCircle);

        this.imagesContainer = new DropViewImagesContainer();
        this.imagesContainer.x = appConfig.width;
        this.addChild(this.imagesContainer);

        const fullGridSize = new Point(appConfig.width, appConfig.height - appConfig.titleHeight);
        const fullGridSpan = new Point(6, 8);
        this.fullCellHighlights = new MonthAllCellHighlights(fullGridSize, fullGridSpan);
    }

    get area() {
        return this._area;
    }

    set area(value) {
        this._area = value;
        this.y = value.y;
        this.imagesContainer.area = value;
        this.info.area = value;
        this.details.area = value;
        this.whiteCircle.area = value;
    }

    get model() {
        return this._model;
    }

    set model(value) {
        this._model = value;
        this.info.model = value;
        this.details.model = value;
        this.imagesContainer.model = value;
        this.whiteCircle.model = value;
    }

    show() {
        return this.imagesContainer.show();
    }

    hide() {
        return this.imagesContainer.hide();
    }

    showFullHighlights() {
        this.addChildAt(this.fullCellHighlights, 0);
        return this.fullCellHighlights.show().then(() => {
            this.removeChild(this.fullCellHighlights);
        });
    }

}
