import * as PIXI from 'pixi.js';
import Timeline from "../../../tsunami/animation/Timeline";
import { appConfig } from "../../AppConfig";
import DropTimeTitleRouter from "../../controller/DropTimeTitleRouter";
import TopTitleSprite from "./TopTitleSprite";
import DropTimeTitles from "./DropTimeTitles";
import TopTitleMask from "./TopTitleMask";
import HOILogo from '../hoi/HOILogo';
import { globalSpriteSheets } from '../GlobalAssets';

export default class TopTitle extends PIXI.Container {

    constructor() {
        super();

        // this.showTimeline = new Timeline();
        // this.hideTimeline = new Timeline();

        this.titles = [];
        this.dropTimeTitles = [];
        this.masks = [];

        const delay = 0.5;
        for (let i = 0; i < 4; i++) {
            const sprite = new TopTitleSprite(i);
            sprite.showDelay = i / 3 * delay;
            sprite.hideDelay = i / 3 * delay;
            this.addChild(sprite);
            this.titles.push(sprite);
            // this.showTimeline.add(sprite.tween1);
            // this.showTimeline.add(sprite.tween2);
            // this.hideTimeline.add(sprite.hideTween);

            const timeTitle = new DropTimeTitles(i / 3 * delay, (i + 1) * (appConfig.width / 4));
            this.addChild(timeTitle);
            timeTitle.x = 50;
            timeTitle.y = 270;
            this.dropTimeTitles.push(timeTitle);

            const mask = new TopTitleMask();
            mask.x = i * (appConfig.width / 4);
            this.addChild(mask);
            sprite.mask = mask;
            timeTitle.mask = mask;
            this.masks.push(mask);
        }

        this.dropTimeTitleRouter = new DropTimeTitleRouter();

        this.hoiLogo = new HOILogo();
        this.hoiLogo.showDelay = 1;
        this.hoiLogo.y = 345;
        this.addChild(this.hoiLogo);
    }

    set model(value) {
        this.dropTimeTitles.forEach((timeTitle) => {
            timeTitle.model = value.timeTitles;
        });
        this.titles.forEach((title) => {
            title.text = value.title;
        });
    }

    show() {
        const promises = [];
        this.titles.forEach((title) => {
            promises.push(title.show());
        });
        return Promise.all(promises);
    }

    hide() {
        const promises = [];
        this.titles.forEach((title) => {
            promises.push(title.hide());
        });
        return Promise.all(promises);
    }

    showDropTimeTitle(name) {
        let promises = [];
        let sprite;
        this.dropTimeTitles.forEach((title) => {
            sprite = title[name];
            title.addChild(sprite);
            const promise1 = sprite.show();
            promises.push(promise1);
        });
        this.hoiLogo.x = sprite.dateText.width + 80;
        promises.push(this.hoiLogo.show());
        return Promise.all(promises);
    }

    hideDropTimeTitle(name) {
        let promises = [];
        this.dropTimeTitles.forEach((title) => {
            promises.push(title[name].hide());
        });
        promises.push(this.hoiLogo.hide());
        return Promise.all(promises);
    }

}
