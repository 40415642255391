import UIListBase from '../../tsunami/components/UIListBase';
import Scope from '../../tsunami/Scope';
import Point from '../../tsunami/geom/Point';
import { PixiListContainer } from "./PixiListContainer";

export default class PixiList extends UIListBase {

    constructor(gridSize, gridSpan, cellClass, showChildrenDelay = 0.03, hideChildrenDelay = 0.03) {
        let element = new PixiListContainer();
        super(element);
        this.showChildrenDelay = showChildrenDelay;
        this.hideChildrenDelay = hideChildrenDelay;
        this.alsoShowChildren = true;
        this.gridSize = gridSize;
        this.gridSpan = gridSpan;
        this.cellClass = cellClass;
        this.cellSize = new Point(this.gridSize.x / this.gridSpan.x, this.gridSize.y / this.gridSpan.y);
    }

    destroyChild(element) {
        element.destroy();
    }

    _createElement(data, index, length) {
        const scope = new Scope(data, this.scope, index, length);
        return new this.cellClass(scope, this.cellSize);
    }

    dispatchResizeEvent() {
        let rowWidth = 0;
        let columnHeight = 0;
        this.children.forEach((child, i) => {
            child.x = rowWidth;
            child.y = columnHeight;
            rowWidth += this.cellSize.x;
            if (Math.round(rowWidth) >= this.gridSize.x) {
                rowWidth = 0;
                columnHeight += this.cellSize.y;
            }
        });

        super.dispatchResizeEvent();
    }
}
