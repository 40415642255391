import { PixiContainer } from "./PixiContainer";
import * as PIXI from 'pixi.js';

export default class AnimatedSpriteContainer extends PixiContainer {

    constructor(animation, animationSpeed = 1, loop = false) {
        super();
        this.sprite = new PIXI.AnimatedSprite(animation);
        this.sprite.loop = loop;
        this.sprite.animationSpeed = animationSpeed;
        this.addChild(this.sprite);
    }

    show(debug = false) {
        // if (debug) {
        //     this.sprite.onFrameChange = () => {
        //         console.log(this.sprite.currentFrame);
        //     }
        // }
        return super.show();
    }

    showDelayComplete() {
        this.sprite.gotoAndPlay(1);
    }

}
