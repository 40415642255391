import { PixiContainer } from "../PixiContainer";
import { awaitTimeout } from "../../../tsunami/await";
import Point from "../../../tsunami/geom/Point";
import { appConfig } from "../../AppConfig";
import PixiImageList from "../PixiImageList";
import MonthSelectedCell from "./MonthSelectedCell";
import MonthSelectedCellHighlights from "./MonthSelectedCellHighlights";
import Rectangle from "../../../tsunami/geom/Rectangle";
import { app } from "../..";

export default class MonthSelectedView extends PixiContainer {

    constructor() {
        super();
        const gridSize = new Point(appConfig.width, appConfig.height - appConfig.titleHeight);
        const gridSpan = new Point(3, 4);
        this.grid = new PixiImageList(gridSize, gridSpan, MonthSelectedCell, 0.03, 0.03);
        this.addChild(this.grid.element);

        this.cellHighlights = new MonthSelectedCellHighlights(gridSize, gridSpan);
    }

    show() {
        const rectangles = {};
        this.grid.children.forEach((child, i) => {
            rectangles[child.scope.data.slug] = new Rectangle(child.x, child.y, child.size.x, child.size.y);
        });
        app.model.monthSelectedRectangles = rectangles;

        this.addChildAt(this.cellHighlights, 0);
        this.cellHighlights.show().then(() => {
            this.removeChild(this.cellHighlights);
        });
        const promise1 = this.grid.show();
        return promise1;
    }

    hide() {
        const promise1 = this.grid.hide();
        return promise1;
    }

    hideDropImmediately(value) {
        const selectedChild = this.grid.children.find((child, i) => {
            return child.scope.data.slug == value.slug;
        });
        selectedChild.skipFlyOut = true;
    }

}