import { awaitTimeout } from "../../tsunami/await";
import Branch from "../../tsunami/Branch";
import Rectangle from "../../tsunami/geom/Rectangle";
import { appConfig } from "../AppConfig";
import { app } from "..";

export default class DayController extends Branch {

    constructor() {
        super();
        this.branches["index"] = new WeekDayController();
        this.branches["details"] = new DropDayController();
        this.defaultChild = "index";
    }

    load() {
        app.model.selectedDrop = app.model.weekDrops.find((drop) => {
            return (drop.slug == this.slug);
        });
        app.model.selectedDropIndex = app.model.weekDrops.indexOf(app.model.selectedDrop);
        app.view.dropProductView.model = app.model.selectedDrop;
    }

    show() {
        if (app.model.monthSelectedRectangles) {
            app.view.monthSelected.hideDropImmediately(app.model.selectedDrop);
        } else {
            app.view.dropProductView.showFullHighlights()
        }

        app.view.circularLoader.setLoadIndex(app.model.selectedDropIndex + 1, 0.5);

        let duration = 5.5;
        if (app.model.selectedDrop.isDroppingToday) {
            duration += 3.5;
            duration += (app.model.selectedDrop.images.length - 1) * 2;
        }
        app.view.circularLoader.router.location = app.model.weekDrops.length + "-week/" + duration + "-" + app.model.selectedDrop.id;

        // app.view.dropProductView.y = appConfig.titleHeight + (appConfig.height - appConfig.titleHeight - app.view.week.grid.gridSize.y - appConfig.width) / 2;
        app.view.stage.addChild(app.view.dropProductView);

        let dropTimeTitleRouterLocation = "week";
        if (app.model.selectedDrop.isDroppingToday) dropTimeTitleRouterLocation = "today";
        app.view.title.dropTimeTitleRouter.location = (app.model.inStockMode) ? "inStock" : dropTimeTitleRouterLocation;

        app.view.week.grid.model = app.model.selectedDrop;

        // console.log("DayController.show router.show.branches.length", this.router.show.branches.length);
        // console.log("DayController.show  router.hide.branches.length", this.router.hide.branches.length);
        return app.view.dropProductView.show().then(() => {
            return app.view.week.grid.nextUpAnimation.show();
        });
    }

    hide() {
        app.view.week.grid.nextUpAnimation.hide();

        const promise1 = app.view.dropProductView.hide();
        const promise2 = promise1.then(() => {
            app.view.stage.removeChild(app.view.dropProductView);
            app.view.dropProductView.imagesContainer.showCircleMask();
        });
        return promise2;
    }

}

export class WeekDayController extends Branch {

    constructor() {
        super();
    }

    load() {
    }

    show() {
        app.view.dropProductView.addChildAt(app.view.dropProductView.info, 0);
        let promise1 = app.view.dropProductView.info.show();
        return promise1.then(() => {
            awaitTimeout(3).then(() => {
                if (app.model.selectedDrop.isDroppingToday && app.model.selectedDrop.hasImageGallery) {
                    const firstImage = app.model.selectedDrop.images[1];
                    this.router.pushState(this.parent.path + "/details/" + firstImage.id);
                } else {
                    gotoNextDrop();
                }
            });
        });
    }

    hide() {
        return app.view.dropProductView.info.hide().then(() => {
            app.view.dropProductView.removeChild(app.view.dropProductView.info);
        });
    }

}

export class DropDayController extends Branch {

    constructor() {
        super();
        this.branches["*"] = new DropDayImageController();
    }

    show() {
        app.view.dropProductView.addChildAt(app.view.dropProductView.details, 0);
        app.view.dropProductView.addChildAt(app.view.dropProductView.whiteCircle, 1);
        app.view.dropProductView.details.show(),
            app.view.dropProductView.showFullHighlights()
        let promise = awaitTimeout(0.66).then(() => {
            return app.view.dropProductView.whiteCircle.show();
        })

        return promise;
    }

    hide() {
        let promise = Promise.all([
            app.view.dropProductView.whiteCircle.hide(),
            app.view.dropProductView.imagesContainer.hideCircleMask(),
            app.view.dropProductView.details.hide(),
        ]).then(() => {
            app.view.dropProductView.removeChild(app.view.dropProductView.details);
            app.view.dropProductView.removeChild(app.view.dropProductView.whiteCircle);
        });
        return promise;
    }

}

export class DropDayImageController extends Branch {

    constructor() {
        super();
        this.goToNextImage = this.goToNextImage.bind(this);
    }

    load() {
        app.model.selectedDropImage = app.model.selectedDrop.images.find((dropImage) => {
            return (dropImage.id == this.slug);
        });
        app.model.selectedDropImageIndex = app.model.selectedDrop.images.indexOf(app.model.selectedDropImage);
    }

    show() {
        return app.view.dropProductView.imagesContainer.images.gotoImage(app.model.selectedDropImageIndex).then(this.goToNextImage);
    }

    goToNextImage() {
        awaitTimeout(2).then(() => {
            const nextImage = app.model.selectedDrop.images[app.model.selectedDropImageIndex + 1];
            if (nextImage) {
                this.router.pushState(this.parent.path + "/" + nextImage.id);
            } else {
                gotoNextDrop()
            }
        });
    }

    hide() {

    }

}

function gotoNextDrop() {
    const nextDrop = app.model.weekDrops[app.model.selectedDropIndex + 1];
    if (nextDrop) {
        app.controller.router.pushState("snkrs/month/week/" + nextDrop.slug);
    } else {
        app.controller.router.location = "loop";
    }
}