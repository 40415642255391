import * as PIXI from 'pixi.js';
import DropTimeTitle from "./DropTimeTitle";

export default class DropTimeTitles extends PIXI.Container {

    constructor(delay, startX) {
        super();
        this.month = new DropTimeTitle(delay, startX);
        // this.addChild(this.month);
        this.week = new DropTimeTitle(delay, startX);
        // this.addChild(this.week);
        this.today = new DropTimeTitle(delay, startX);
        // this.addChild(this.today);
        this.inStock = new DropTimeTitle(delay, startX);
    }

    set model(value) {
        this.month.model = value.month;
        this.week.model = value.week;
        this.today.model = value.today;
        this.inStock.model = value.inStock;
    }

}