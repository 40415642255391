import * as PIXI from 'pixi.js';
import Rectangle from '../../../tsunami/geom/Rectangle';
import { dayMonthFormatter, format24hTime, formatAMPM } from '../../../tsunami/utils/date';
import { addLeadingZero } from '../../../tsunami/utils/number';
import { appConfig } from "../../AppConfig";
import { app } from '../..';
import EncryptedText from '../EncryptedText';
import { PixiContainer } from '../PixiContainer';
import { addBreaks } from '../utils';

export default class DropViewInfoContainer extends PixiContainer {

    constructor() {
        super();
        this.margin = 40;

        this._area = new Rectangle();

        this.titleStyle = {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 60,
            fill: 0xffffff,
            align: 'left',
            wordWrap: false,
            wordWrapWidth: (appConfig.width - this.margin * 2) * 0.75
        }
        this.titleText = new EncryptedText('This is a PixiJS text', this.titleStyle);
        this.addChild(this.titleText);
        this.titleText.x = this.margin;
        this.titleText.y = this.margin;

        // this.descriptionStyle = {
        //     fontFamily: 'NeueHelveticaforHOI',
        //     fontSize: 26,
        //     fill: 0xffffff,
        //     align: 'left',
        //     wordWrap: false,
        //     wordWrapWidth: (appConfig.width - this.margin * 2) * 0.85
        // }
        // this.descriptionText = new EncryptedText('This is a PixiJS text', this.descriptionStyle);
        // this.descriptionText.x = this.margin;
        // this.addChild(this.descriptionText);

        this.animationFrame = this.animationFrame.bind(this);

        // let bitmapText = new PIXI.BitmapText("text using a fancy font!", { font: "35px NeueHelveticaforHOI", align: "right" });
        // this.addChild(bitmapText);
    }

    get area() {
        return this._area;
    }

    set area(value) {
        this._area = value;
    }

    truncateDescription(value) {
        const text = value.substr(0, Math.min(256, value.length));
        const array = text.split(".");
        if (array.length > 1) {
            array[array.length - 1] = "";
        }
        return array.join(".");
    }

    get model() {
        return this._model;
    }

    set model(value) {
        this._model = value;

        this.titleModel = addBreaks(this.model.fulltitle.toUpperCase(), this.titleText.style, 3);
        // this.titleText.x = (this.area.width - this.titleText.style.wordWrapWidth) / 2;
        this.updateTitle();
        this.titleText.calculateTextHeight();
        this.titleText.y = this.area.height - this.titleText.encryptedTextHeight - this.margin;

        // let descriptionTextModel = this.truncateDescription(value.description);
        // descriptionTextModel = addBreaks(descriptionTextModel.toUpperCase(), this.descriptionText.style);
        // this.descriptionText.model = descriptionTextModel;
        // this.descriptionText.calculateTextHeight();
        // // this.descriptionText.y = appConfig.width - this.descriptionText.encryptedTextHeight - this.margin;
        // this.descriptionText.y = this.area.height - this.descriptionText.encryptedTextHeight - this.margin;
    }

    showDelayComplete() {
        this.hits = 0;
        super.showDelayComplete();
        let promise1 = Promise.all([
            this.titleText.show(),
            // this.descriptionText.show()
        ]);
        let promise2 = promise1.then(() => {
            if (this.model.isDroppingWithin24Hours) {
                this.animationFrame();
            }
        });
        return promise2;
    }

    animationFrame() {
        if (this.hits % 3 == 0) {
            this.updateTitle();
        }
        this.hits++;
        if (this.isVisible) {
            requestAnimationFrame(this.animationFrame);
        }
    }

    updateTitle() {
        const day = addLeadingZero(this.model.startEntryDate.getDate());
        const month = addLeadingZero(this.model.startEntryDate.getMonth() + 1);
        let date = `/ ${dayMonthFormatter(day, month, app.model.dateFormat)} /`;
        let time = app.model.city.use24HourTime ? format24hTime(this.model.startEntryDate) : formatAMPM(this.model.startEntryDate, " ").toUpperCase();

        if (this.model.isDroppingToday) {
            date = app.model.copydeck.today.toUpperCase();
        }
        if (this.model.isDroppingWithin24Hours) {
            time = this.model.getDropTime();
        }
        let titleModel = this.titleModel;
        if (!this.model.inStock) {
            titleModel += "\n" + date + " " + time;
        }
        this.titleText.model = titleModel;
    }

    hideDelayComplete() {
        this.isVisible = false;
        const promises = [
            this.titleText.hide(),
            // this.descriptionText.hide()
        ];
        return Promise.all(promises);
    }

}
