import { PixiListCellFromTop } from "../PixiImageListCell";
import { addLeadingZero } from '../../../tsunami/utils/number';
import EncryptedText from '../EncryptedText';
import { dayMonthFormatter, format24hTime, formatAMPM } from "../../../tsunami/utils/date";
import { addBreaks } from "../utils";
import { app } from "../..";

export default class MonthSelectedCell extends PixiListCellFromTop {

    constructor(scope, size) {
        scope.positionOffset = { x: 0, y: -0.025 };
        super(scope, size);

        this.margin = 29;
        this.dateText = new EncryptedText('This is a PixiJS text', {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 26,
            fontWeight: "normal",
            fill: 0xffffff,
            align: 'left',
            wordWrap: false,
            wordWrapWidth: (size.x - this.margin * 2) * 0.66
        });
        this.addChild(this.dateText);
        this.dateText.x = this.margin;
        this.dateText.y = 23;

        this.titleText = new EncryptedText('This is a PixiJS text', {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 26,
            fontWeight: "normal",
            fill: 0xffffff,
            align: 'left',
            wordWrap: false,
            wordWrapWidth: (size.x - this.margin * 2)
        }, this.debug);
        this.titleText.x = this.margin;
        this.addChild(this.titleText);

        this.animationFrame = this.animationFrame.bind(this);

        this.model = scope.data;
    }

    destroy() {
        super.destroy();
        this.titleText = null;
        this.dateText = null;
    }

    get model() {
        return this._model;
    }

    set model(value) {
        this._model = value;

        if (!this.model.inStock) {
            this.addChild(this.dateText);
        } else {
            this.removeChild(this.dateText);
        }

        this.updateDate(true);

        let titleModel = "";
        // if (this.model.fulltitle) titleModel += this.model.fulltitle.toUpperCase();
        // titleModel = addBreaks(titleModel, this.titleText.style);
        titleModel = addBreaks(this.model.subtitle.toUpperCase() || this.model.title.toUpperCase(), this.titleText.style, 2);
        this.titleText.model = titleModel;
        this.titleText.calculateTextHeight();
        // this.titleText.y = this.size.y - this.titleText.encryptedTextHeight - this.margin;
        this.titleText.y = this.size.y - 90;
    }

    showDelayComplete() {
        this.alpha = 1;
        this.hits = 0;
        const promise1 = super.showDelayComplete();
        const promise2 = promise1.then(() => {
            return Promise.all([
                this.dateText.show(),
                this.titleText.show(),
            ]);
        });
        const promise3 = promise2.then(() => {
            if (this.model.isDroppingWithin24Hours) {
                this.animationFrame();
            }
        });
        return promise3;
    }

    animationFrame() {
        if (this.hits % 3 == 0) {
            this.updateDate();
        }
        this.hits++;
        if (this.isVisible) {
            requestAnimationFrame(this.animationFrame);
        }
    }

    updateDate(setTimetoZero) {
        const day = addLeadingZero(this.model.startEntryDate.getDate());
        const month = addLeadingZero(this.model.startEntryDate.getMonth() + 1);
        let date = `/ ${dayMonthFormatter(day, month, app.model.city.dateFormat)} /`;
        let time = app.model.city.use24HourTime ? format24hTime(this.model.startEntryDate) : formatAMPM(this.model.startEntryDate, " ").toUpperCase();

        if (this.model.isDroppingToday) {
            date = app.model.copydeck.today.toUpperCase();
        }
        if (this.model.isDroppingWithin24Hours) {
            time = this.model.getDropTime();
        }
        this.dateText.model = date + "\n" + time;
    }

    hideDelayComplete() {
        this.isVisible = false;
        const promise1 = Promise.all([
            this.dateText.hide(),
            this.titleText.hide(),
        ]);
        const promise2 = promise1.then(() => {
            if (this.skipFlyOut) {
                this.alpha = 0;
                return Promise.resolve();
            } else {
                return super.hideDelayComplete();
            }
        });
        return promise2;
    }
}
