import Easing from "../../../tsunami/animation/Easing";
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import { PixiContainer } from "../PixiContainer";
import CircleProgressLine from "./CircleProgressLine";

export default class CircleProgress extends PixiContainer {

    constructor(outerRadius, innerRadius) {
        super();

        this._lineVisibility = 0;

        this.right = new CircleProgressLine(outerRadius, innerRadius, -Math.PI / 2);
        this.addChild(this.right);

        this.left = new CircleProgressLine(outerRadius, innerRadius, Math.PI / 2);
        this.addChild(this.left);

        this.showTween = new Tween(0, 0.66, [new TweenProperty(this, "lineVisibility", 0, 1, Easing.cubic.easeInOut)]);
        this.hideTween = new Tween(0, 0.66, [new TweenProperty(this, "lineVisibility", 1, 0, Easing.cubic.easeInOut)]);

        this.lineVisibility = 0;
    }

    get lineVisibility() {
        return this._lineVisibility;
    }

    set lineVisibility(value) {
        this._lineVisibility = value;

        const leftMultiplier = Math.min(0.5, value) / 0.5;
        this.left.shape.rotation = Math.PI * (leftMultiplier);

        const rightMultiplier = Math.max(0, value - 0.5) / 0.5;
        this.right.shape.rotation = Math.PI * (rightMultiplier);
    }

    get progress() {
        return this._progress;
    }

    set progress(value) {
        this._progress = value;

        const rightMultiplier = Math.min(0.5, value) / 0.5;
        this.left.maskShape.rotation = Math.PI * (rightMultiplier);

        const leftMultiplier = Math.max(0, value - 0.5) / 0.5;
        this.right.maskShape.rotation = Math.PI * (leftMultiplier);
    }

    startTimer(duration = 5) {
        return new Tween(0, duration, [new TweenProperty(this, "progress", 0, 1, Easing.linear.ease)]).start();
    }

    showDelayComplete() {
        super.showDelayComplete();
        this.left.maskShape.rotation = 0;
        this.right.maskShape.rotation = 0;
        return this.showTween.start();
    }

    hideDelayComplete() {
        this.left.maskShape.rotation = 0;
        this.right.maskShape.rotation = 0;
        return this.hideTween.start();
    }



}