import { appConfig } from "../../AppConfig";
import { PixiContainer } from "../PixiContainer";
import * as PIXI from 'pixi.js';
import Point from "../../../tsunami/geom/Point";
import Timeline from "../../../tsunami/animation/Timeline";
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Easing from "../../../tsunami/animation/Easing";

export class SquareGridLines extends PixiContainer {

    constructor() {
        super();
        this.linesH = [];
        let lineSize = new Point(appConfig.width, appConfig.lineWidth);
        let gap = (appConfig.height - appConfig.titleHeight) / 8;
        for (let i = 0; i < 8; i++) {
            const line = new PIXI.Graphics();
            line.lineStyle(0);
            line.beginFill(0xffffff, 1);
            line.moveTo(0, 0);
            line.lineTo(lineSize.x, 0);
            line.lineTo(lineSize.x, lineSize.y);
            line.lineTo(0, lineSize.y);
            line.x = lineSize.x;
            line.y = i * gap;
            line.pivot.x = lineSize.x;
            this.addChild(line);
            this.linesH.push(line);
        }

        this.linesV = [];
        lineSize = new Point(appConfig.lineWidth, appConfig.height - appConfig.titleHeight);
        gap = appConfig.width / 6;
        for (let i = 1; i < 6; i++) {
            const line = new PIXI.Graphics();
            line.lineStyle(0);
            line.beginFill(0xffffff, 1);
            line.moveTo(0, 0);
            line.lineTo(lineSize.x, 0);
            line.lineTo(lineSize.x, lineSize.y);
            line.lineTo(0, lineSize.y);
            line.x = i * gap;
            line.y = lineSize.y;
            line.pivot.y = lineSize.y;
            this.addChild(line);
            this.linesV.push(line);
        }
    }

    hideLinesForMonthSelected() {
        const linesVIndices = [0, 2, 4];
        const linesHIndices = [1, 3, 5, 7];
        const tweenProps = [];
        linesVIndices.forEach((index) => {
            tweenProps.push(new TweenProperty(this.linesV[index].scale, "y", 1, 0, Easing.cubic.easeInOut));
        })
        linesHIndices.forEach((index) => {
            tweenProps.push(new TweenProperty(this.linesH[index].scale, "x", 1, 0, Easing.cubic.easeInOut));
        })
        return new Tween(0, 0.5, tweenProps).start();
    }

    hideLinesForWeek() {
        const tweenProps = [];
        this.linesV.forEach((line, i) => {
            tweenProps.push(new TweenProperty(line.scale, "y", line.scale.y, 0, Easing.cubic.easeInOut));
        });
        this.linesH.forEach((line, i) => {
            if (i > 0) tweenProps.push(new TweenProperty(line.scale, "x", line.scale.x, 0, Easing.cubic.easeInOut));
        });
        return new Tween(0, 0.66, tweenProps).start();
    }

    hideAll() {
        const tweenProps = [];
        this.linesV.forEach((line) => {
            tweenProps.push(new TweenProperty(line.scale, "y", line.scale.y, 0, Easing.cubic.easeInOut));
        })
        this.linesH.forEach((line) => {
            tweenProps.push(new TweenProperty(line.scale, "x", line.scale.x, 0, Easing.cubic.easeInOut));
        })
        return new Tween(0, 0.66, tweenProps).start();
    }

    resetAllLines() {
        this.linesV.forEach((line) => {
            line.scale.y = 1;
        });
        this.linesH.forEach((line) => {
            line.scale.x = 1;
        });
    }

}