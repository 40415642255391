import { addLeadingZero } from '../../../tsunami/utils/number';
import * as PIXI from 'pixi.js';
import Timeline from '../../../tsunami/animation/Timeline';
import Tween from '../../../tsunami/animation/Tween';
import TweenProperty from '../../../tsunami/animation/TweenProperty';
import Easing from '../../../tsunami/animation/Easing';
import { dayMonthFormatter } from '../../../tsunami/utils/date';
import { app } from '../..';

export default class MonthCellDateText extends PIXI.Text {

    constructor(text, style) {
        super("", style);
        this._monthMultiplier = 0;
        this._dayMultiplier = 0;

        this.alpha = 0;

        this.updateDateText = this.updateDateText.bind(this);

        this.showTimeline = new Timeline([
            new Tween(0, 0.66, [new TweenProperty(this, "dayMultiplier", 0, 1, Easing.quad.easeOut)]),
            new Tween(0, 0.66, [new TweenProperty(this, "monthMultiplier", 0, 1, Easing.quad.easeOut)])
        ], 0, this.updateDateText);

        this.hideTimeline = new Timeline([
            new Tween(0, 0.5, [new TweenProperty(this, "dayMultiplier", 1, 0, Easing.quad.easeIn)]),
            new Tween(0, 0.5, [new TweenProperty(this, "monthMultiplier", 1, 0, Easing.quad.easeIn)])
        ], 0, this.updateDateText);
    }

    get model() {
        return this._model;
    }

    set model(value) {
        this._model = value;
        this.updateDateText();
    }

    get dayMultiplier() {
        return this._dayMultiplier;
    }

    set dayMultiplier(value) {
        this._dayMultiplier = value;
    }

    get monthMultiplier() {
        return this._monthMultiplier;
    }

    set monthMultiplier(value) {
        this._monthMultiplier = value;
    }

    updateDateText() {
        const day = addLeadingZero(
			Math.round(this.model.getDate() * this.dayMultiplier)
		);
        const month = addLeadingZero(
			Math.round((this.model.getMonth() + 1) * this.monthMultiplier)
		);

        this.text = dayMonthFormatter(day, month, app.model.city.dateFormat);
    }

    show() {
        this.alpha = 1;
        const promise1 = this.showTimeline.start();
        return promise1;
    }

    hide() {
        const promise1 = this.hideTimeline.start();
        const promise2 = promise1.then(() => {
            this.alpha = 0;
        });
        return promise2;
    }

}
