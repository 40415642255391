import { awaitTimeout } from "../../tsunami/await";
import Branch from "../../tsunami/Branch";
import Rectangle from "../../tsunami/geom/Rectangle";
import { appConfig } from "../AppConfig";
import { app } from "..";
import DayController from "./DayController";

export default class WeekController extends Branch {

    constructor() {
        super();
        this.branches["*"] = new DayController();
        app.view.week.provider = app.model.weekDrops;
    }

    get defaultChild() {
        return app.model.weekDrops[0].slug;
    }

    load() {
        app.view.stage.addChild(app.view.week);
        app.view.dropProductView.area = new Rectangle(0, appConfig.titleHeight, appConfig.width, (appConfig.height - appConfig.titleHeight) - app.view.week.grid.gridSize.y);
    }

    show() {
        awaitTimeout(1.25).then(() => {
            app.view.squareGridWhite.squareGridLines.hideLinesForWeek();
        })
        app.view.circularLoader.router.location = app.model.weekDrops.length + "-week";
        app.view.week.show();
    }

    hide() {
        app.view.week.hide().then(() => {
            app.view.stage.removeChild(app.view.week);
        });
        return awaitTimeout(0.5);
    }

}

