{
	"frames": {
		"bar1_0.png": {
			"frame": {
				"x": 0,
				"y": 172,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_1.png": {
			"frame": {
				"x": 713,
				"y": 172,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_2.png": {
			"frame": {
				"x": 0,
				"y": 328,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_3.png": {
			"frame": {
				"x": 713,
				"y": 458,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_4.png": {
			"frame": {
				"x": 713,
				"y": 536,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_5.png": {
			"frame": {
				"x": 0,
				"y": 562,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_6.png": {
			"frame": {
				"x": 713,
				"y": 562,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_7.png": {
			"frame": {
				"x": 0,
				"y": 588,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_8.png": {
			"frame": {
				"x": 713,
				"y": 588,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_9.png": {
			"frame": {
				"x": 0,
				"y": 614,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_10.png": {
			"frame": {
				"x": 0,
				"y": 198,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_11.png": {
			"frame": {
				"x": 713,
				"y": 198,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_12.png": {
			"frame": {
				"x": 0,
				"y": 224,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_13.png": {
			"frame": {
				"x": 713,
				"y": 224,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_14.png": {
			"frame": {
				"x": 0,
				"y": 250,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_15.png": {
			"frame": {
				"x": 713,
				"y": 250,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_16.png": {
			"frame": {
				"x": 0,
				"y": 276,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_17.png": {
			"frame": {
				"x": 713,
				"y": 276,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_18.png": {
			"frame": {
				"x": 0,
				"y": 302,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_19.png": {
			"frame": {
				"x": 713,
				"y": 302,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_20.png": {
			"frame": {
				"x": 713,
				"y": 328,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_21.png": {
			"frame": {
				"x": 0,
				"y": 354,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_22.png": {
			"frame": {
				"x": 713,
				"y": 354,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_23.png": {
			"frame": {
				"x": 0,
				"y": 380,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_24.png": {
			"frame": {
				"x": 713,
				"y": 380,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_25.png": {
			"frame": {
				"x": 0,
				"y": 406,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_26.png": {
			"frame": {
				"x": 713,
				"y": 406,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_27.png": {
			"frame": {
				"x": 0,
				"y": 432,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_28.png": {
			"frame": {
				"x": 713,
				"y": 432,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_29.png": {
			"frame": {
				"x": 0,
				"y": 458,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_30.png": {
			"frame": {
				"x": 0,
				"y": 484,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_31.png": {
			"frame": {
				"x": 713,
				"y": 484,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_32.png": {
			"frame": {
				"x": 0,
				"y": 510,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_33.png": {
			"frame": {
				"x": 713,
				"y": 510,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar1_34.png": {
			"frame": {
				"x": 0,
				"y": 536,
				"w": 713,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 713,
				"h": 26
			},
			"sourceSize": {
				"w": 713,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_0.png": {
			"frame": {
				"x": 713,
				"y": 614,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_1.png": {
			"frame": {
				"x": 0,
				"y": 640,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_2.png": {
			"frame": {
				"x": 715,
				"y": 770,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_3.png": {
			"frame": {
				"x": 715,
				"y": 874,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_4.png": {
			"frame": {
				"x": 0,
				"y": 900,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_5.png": {
			"frame": {
				"x": 715,
				"y": 900,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_6.png": {
			"frame": {
				"x": 0,
				"y": 926,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_7.png": {
			"frame": {
				"x": 715,
				"y": 926,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_8.png": {
			"frame": {
				"x": 0,
				"y": 952,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_9.png": {
			"frame": {
				"x": 715,
				"y": 952,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_10.png": {
			"frame": {
				"x": 715,
				"y": 640,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_11.png": {
			"frame": {
				"x": 0,
				"y": 666,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_12.png": {
			"frame": {
				"x": 715,
				"y": 666,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_13.png": {
			"frame": {
				"x": 0,
				"y": 692,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_14.png": {
			"frame": {
				"x": 715,
				"y": 692,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_15.png": {
			"frame": {
				"x": 0,
				"y": 718,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_16.png": {
			"frame": {
				"x": 715,
				"y": 718,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_17.png": {
			"frame": {
				"x": 0,
				"y": 744,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_18.png": {
			"frame": {
				"x": 715,
				"y": 744,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_19.png": {
			"frame": {
				"x": 0,
				"y": 770,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_20.png": {
			"frame": {
				"x": 0,
				"y": 796,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_21.png": {
			"frame": {
				"x": 715,
				"y": 796,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_22.png": {
			"frame": {
				"x": 0,
				"y": 822,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_23.png": {
			"frame": {
				"x": 715,
				"y": 822,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_24.png": {
			"frame": {
				"x": 0,
				"y": 848,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_25.png": {
			"frame": {
				"x": 715,
				"y": 848,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar2_26.png": {
			"frame": {
				"x": 0,
				"y": 874,
				"w": 715,
				"h": 26
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 715,
				"h": 26
			},
			"sourceSize": {
				"w": 715,
				"h": 26
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_0.png": {
			"frame": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_1.png": {
			"frame": {
				"x": 26,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_2.png": {
			"frame": {
				"x": 312,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_3.png": {
			"frame": {
				"x": 520,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_4.png": {
			"frame": {
				"x": 546,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_5.png": {
			"frame": {
				"x": 572,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_6.png": {
			"frame": {
				"x": 598,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_7.png": {
			"frame": {
				"x": 624,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_8.png": {
			"frame": {
				"x": 650,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_9.png": {
			"frame": {
				"x": 676,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_10.png": {
			"frame": {
				"x": 52,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_11.png": {
			"frame": {
				"x": 78,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_12.png": {
			"frame": {
				"x": 104,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_13.png": {
			"frame": {
				"x": 130,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_14.png": {
			"frame": {
				"x": 156,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_15.png": {
			"frame": {
				"x": 182,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_16.png": {
			"frame": {
				"x": 208,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_17.png": {
			"frame": {
				"x": 234,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_18.png": {
			"frame": {
				"x": 260,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_19.png": {
			"frame": {
				"x": 286,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_20.png": {
			"frame": {
				"x": 338,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_21.png": {
			"frame": {
				"x": 364,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_22.png": {
			"frame": {
				"x": 390,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_23.png": {
			"frame": {
				"x": 416,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_24.png": {
			"frame": {
				"x": 442,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_25.png": {
			"frame": {
				"x": 468,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar3_26.png": {
			"frame": {
				"x": 494,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_0.png": {
			"frame": {
				"x": 702,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_1.png": {
			"frame": {
				"x": 728,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_2.png": {
			"frame": {
				"x": 1014,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_3.png": {
			"frame": {
				"x": 1222,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_4.png": {
			"frame": {
				"x": 1248,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_5.png": {
			"frame": {
				"x": 1274,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_6.png": {
			"frame": {
				"x": 1300,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_7.png": {
			"frame": {
				"x": 1326,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_8.png": {
			"frame": {
				"x": 1352,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_9.png": {
			"frame": {
				"x": 1378,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_10.png": {
			"frame": {
				"x": 754,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_11.png": {
			"frame": {
				"x": 780,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_12.png": {
			"frame": {
				"x": 806,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_13.png": {
			"frame": {
				"x": 832,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_14.png": {
			"frame": {
				"x": 858,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_15.png": {
			"frame": {
				"x": 884,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_16.png": {
			"frame": {
				"x": 910,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_17.png": {
			"frame": {
				"x": 936,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_18.png": {
			"frame": {
				"x": 962,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_19.png": {
			"frame": {
				"x": 988,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_20.png": {
			"frame": {
				"x": 1040,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_21.png": {
			"frame": {
				"x": 1066,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_22.png": {
			"frame": {
				"x": 1092,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_23.png": {
			"frame": {
				"x": 1118,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_24.png": {
			"frame": {
				"x": 1144,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_25.png": {
			"frame": {
				"x": 1170,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		},
		"bar4_26.png": {
			"frame": {
				"x": 1196,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"rotated": false,
			"trimmed": false,
			"spriteSourceSize": {
				"x": 0,
				"y": 0,
				"w": 26,
				"h": 172
			},
			"sourceSize": {
				"w": 26,
				"h": 172
			},
			"anchor": {
				"x": 0.5,
				"y": 0.5
			}
		}
	},
	"animations": {
		"bar1": [
			"bar1_0.png",
			"bar1_1.png",
			"bar1_2.png",
			"bar1_3.png",
			"bar1_4.png",
			"bar1_5.png",
			"bar1_6.png",
			"bar1_7.png",
			"bar1_8.png",
			"bar1_9.png",
			"bar1_10.png",
			"bar1_11.png",
			"bar1_12.png",
			"bar1_13.png",
			"bar1_14.png",
			"bar1_15.png",
			"bar1_16.png",
			"bar1_17.png",
			"bar1_18.png",
			"bar1_19.png",
			"bar1_20.png",
			"bar1_21.png",
			"bar1_22.png",
			"bar1_23.png",
			"bar1_24.png",
			"bar1_25.png",
			"bar1_26.png",
			"bar1_27.png",
			"bar1_28.png",
			"bar1_29.png",
			"bar1_30.png",
			"bar1_31.png",
			"bar1_32.png",
			"bar1_33.png",
			"bar1_34.png"
		],
		"bar2": [
			"bar2_0.png",
			"bar2_1.png",
			"bar2_2.png",
			"bar2_3.png",
			"bar2_4.png",
			"bar2_5.png",
			"bar2_6.png",
			"bar2_7.png",
			"bar2_8.png",
			"bar2_9.png",
			"bar2_10.png",
			"bar2_11.png",
			"bar2_12.png",
			"bar2_13.png",
			"bar2_14.png",
			"bar2_15.png",
			"bar2_16.png",
			"bar2_17.png",
			"bar2_18.png",
			"bar2_19.png",
			"bar2_20.png",
			"bar2_21.png",
			"bar2_22.png",
			"bar2_23.png",
			"bar2_24.png",
			"bar2_25.png",
			"bar2_26.png"
		],
		"bar3": [
			"bar3_0.png",
			"bar3_1.png",
			"bar3_2.png",
			"bar3_3.png",
			"bar3_4.png",
			"bar3_5.png",
			"bar3_6.png",
			"bar3_7.png",
			"bar3_8.png",
			"bar3_9.png",
			"bar3_10.png",
			"bar3_11.png",
			"bar3_12.png",
			"bar3_13.png",
			"bar3_14.png",
			"bar3_15.png",
			"bar3_16.png",
			"bar3_17.png",
			"bar3_18.png",
			"bar3_19.png",
			"bar3_20.png",
			"bar3_21.png",
			"bar3_22.png",
			"bar3_23.png",
			"bar3_24.png",
			"bar3_25.png",
			"bar3_26.png"
		],
		"bar4": [
			"bar4_0.png",
			"bar4_1.png",
			"bar4_2.png",
			"bar4_3.png",
			"bar4_4.png",
			"bar4_5.png",
			"bar4_6.png",
			"bar4_7.png",
			"bar4_8.png",
			"bar4_9.png",
			"bar4_10.png",
			"bar4_11.png",
			"bar4_12.png",
			"bar4_13.png",
			"bar4_14.png",
			"bar4_15.png",
			"bar4_16.png",
			"bar4_17.png",
			"bar4_18.png",
			"bar4_19.png",
			"bar4_20.png",
			"bar4_21.png",
			"bar4_22.png",
			"bar4_23.png",
			"bar4_24.png",
			"bar4_25.png",
			"bar4_26.png"
		]
	},
	"meta": {
		"app": "https://www.codeandweb.com/texturepacker",
		"version": "1.0",
		"image": "bars.png",
		"format": "RGBA8888",
		"size": {
			"w": 1430,
			"h": 978
		},
		"scale": "1",
		"smartupdate": "$TexturePacker:SmartUpdate:ebe3f98b7d499bb130658128123cacca:0bb77ebca9c5dee2dab21085c66ea84a:06406aff1e8cc29d00eea9c6e43b13d0$"
	}
}
