import { globalTextures } from "../GlobalAssets";
import { PixiContainer } from "../PixiContainer";
import NikeSprite from "../NikeSprite";
import CircleInfoText from "./CircleInfoText";

export default class CircleInfo extends PixiContainer {

    constructor() {
        super();

        this.text = new CircleInfoText();
        this.text.x = -70;
        this.text.y = -15;
        this.text.hideDelay = 0.25;
        this.addChild(this.text);

        this.nikeSprite = new NikeSprite(globalTextures.nike.white);
        this.nikeSprite.showDelay = 0.25;
        this.nikeSprite.scale.x = this.nikeSprite.scale.y = 1.5;
        this.nikeSprite.x = 20;
        this.nikeSprite.y = -10;
        this.addChild(this.nikeSprite);
    }

    showDelayComplete() {
        super.showDelayComplete();
        const promises = [
            this.text.show(),
            this.nikeSprite.show()
        ];
        const promise1 = Promise.all(promises);
        return promise1;
    }

    hideDelayComplete() {
        const promises = [
            this.text.hide(),
            this.nikeSprite.hide()
        ];
        const promise1 = Promise.all(promises);
        return promise1;
    }


}
