import * as PIXI from 'pixi.js';
import Easing from '../../../tsunami/animation/Easing';
import Timeline from '../../../tsunami/animation/Timeline';
import Tween from '../../../tsunami/animation/Tween';
import TweenProperty from '../../../tsunami/animation/TweenProperty';
import { PixiContainer } from '../PixiContainer';

export default class CircleLine extends PixiContainer {

    constructor(lineRadius) {
        super();
        this.debug = true;

        this.lineRadius = lineRadius;
        this.line = new PIXI.Graphics();
        this.addChild(this.line);

        this.showTimeline = new Timeline();
        this.showTimeline.add(new Tween(0, 1, [
            new TweenProperty(this, "circMultiplier", 0, 1, Easing.cubic.easeInOut),
            new TweenProperty(this, "lineRotationMultiplier", 0, 1, Easing.linear.ease)
        ]));

        this.hideTimeline = new Timeline();
        this.hideTimeline.add(new Tween(0, 1, [
            new TweenProperty(this, "circMultiplier", 1, 0, Easing.cubic.easeInOut),
            new TweenProperty(this, "lineRotationMultiplier", 1, 0, Easing.linear.ease)
        ]));

        this.lineRotation = 90;
    }

    get circMultiplier() {
        return this._circMultiplier;
    }

    set circMultiplier(value) {
        this._circMultiplier = value;
        this.line.clear();
        this.line.lineStyle(1, 0xffffff, 1);
        const lineRot = Math.PI / 180 * (this.lineRotationMultiplier * this.lineRotation - 90);
        this.line.arc(0, 0, this.lineRadius, lineRot, lineRot + Math.PI * 2 * value);
    }

    showDelayComplete() {
        const promise1 = this.showTimeline.start();
        return promise1;
    }

    hideDelayComplete() {
        const promise1 = this.hideTimeline.start();
        return promise1;
    }

}