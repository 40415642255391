import { awaitTimeout } from "../../../tsunami/await";
import Point from "../../../tsunami/geom/Point";
import { appConfig } from "../../AppConfig";
import { PixiContainer } from "../PixiContainer";
import PixiImageList from "../PixiImageList";
import MonthAllCellHighlights from "./MonthAllCellHighlights";
import MonthCell from "./MonthCell";

export default class MonthAllView extends PixiContainer {

    constructor() {
        super();
        const gridSize = new Point(appConfig.width, appConfig.height - appConfig.titleHeight);
        const gridSpan = new Point(6, 8);

        this.cellHighlights = new MonthAllCellHighlights(gridSize, gridSpan);

        this.grid = new PixiImageList(gridSize, gridSpan, MonthCell, 0.05, 0.025);
        this.addChild(this.grid.element);
    }

    show() {
        const promise1 = this.grid.show();
        const promise2 = awaitTimeout(1.5);
        const promise3 = promise2.then(() => {
            this.addChildAt(this.cellHighlights, 0);
            return this.cellHighlights.show();
        })
        promise3.then(() => {
            this.removeChild(this.cellHighlights);
        })
        return promise1;
    }

    hide() {
        this.addChildAt(this.cellHighlights, 0);
        this.cellHighlights.hide().then(() => {
            this.removeChild(this.cellHighlights);
        });
        const promise1 = awaitTimeout(0.15);
        const promise2 = promise1.then(() => {
            return this.grid.hide();
        });
        return promise2;
    }

}