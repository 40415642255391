import Router from '../../../tsunami/Router';
import { app } from '../..';
import { PixiContainer } from '../PixiContainer';
import CircleInfo from './CircleInfo';
import CircleLine from './CircleLine';
import CircleProgress from './CircleProgress';
import CircleTextLine from './CircleTextLine';
import CircularLoaderController from './controller/CircularLoaderController';

export default class CircularLoader extends PixiContainer {

    constructor() {
        super();
        this.outerLine = new CircleLine(113);
        this.outerLine.showDelay = 0;
        this.addChild(this.outerLine);

        this.innerLine = new CircleLine(103);
        this.innerLine.showDelay = 0.5;
        this.addChild(this.innerLine);

        this.textLine = new CircleTextLine(app.model.copydeck.loading_more.toUpperCase(), 113);
        this.textLine.showDelay = 1;
        this.addChild(this.textLine);

        this.progressLine = new CircleProgress(113, 103);
        this.addChild(this.progressLine);

        this.circleInfo = new CircleInfo();
        this.addChild(this.circleInfo);

        this.router = new Router(new CircularLoaderController());
    }

    showDelayComplete() {
        super.showDelayComplete();
        const promises = [
            this.outerLine.show(),
            this.innerLine.show(),
            this.textLine.show(),
            this.circleInfo.show(),
        ];
        const promise1 = Promise.all(promises);
        return promise1;
    }

    hideDelayComplete() {
        const promises = [
            this.outerLine.hide(),
            this.innerLine.hide(),
            this.textLine.hide(),
            this.circleInfo.hide(),
        ];
        const promise1 = Promise.all(promises);
        return promise1;
    }

    startTimer(duration) {
        return this.progressLine.startTimer(duration);
    }

    setLoadTotal(value, duration = 0) {
        if (duration > 0) {
            return this.circleInfo.text.totalText.tweenTo(value);
        } else {
            this.circleInfo.text.totalText.number = value;
        }
    }

    setLoadIndex(value, duration = 0) {
        if (duration > 0) {
            return this.circleInfo.text.countText.tweenTo(value, duration);
        } else {
            this.circleInfo.text.countText.number = value;
        }
    }

}
