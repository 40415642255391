import * as PIXI from 'pixi.js';
import { appConfig } from '../../AppConfig';

export default class Background extends PIXI.Container {

    constructor(square_grid) {
        super();
        const cutting_mat = PIXI.Texture.from(require('../../../../static/assets/images/cutting_mat.png'));
        const cuttingMat = new PIXI.Sprite(cutting_mat);
        cuttingMat.x = 0;
        cuttingMat.y = 0;
        cuttingMat.width = appConfig.width;
        cuttingMat.height = appConfig.height;
        this.addChild(cuttingMat);

        const dot_grid = PIXI.Texture.from(require('../../../../static/assets/images/dot_grid.png'));
        const dotGrid = new PIXI.Sprite(dot_grid);
        dotGrid.x = 0;
        dotGrid.y = 441;
        dotGrid.width = appConfig.width;
        dotGrid.height = appConfig.height - 441;
        this.addChild(dotGrid);

        const squareGrid = new PIXI.Sprite(square_grid);
        squareGrid.x = 0;
        squareGrid.y = appConfig.titleHeight;
        squareGrid.width = appConfig.width;
        squareGrid.height = appConfig.height - appConfig.titleHeight;
        squareGrid.alpha = 0.1;
        this.addChild(squareGrid);
    }

}
