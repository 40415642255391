import Branch from "../../../../tsunami/Branch";
import { app } from "../../..";
import CircularLoaderTimerController from "./CircularLoaderTimerController";

export default class CircularLoaderSectionController extends Branch {

    constructor() {
        super();
        this.branches["*"] = new CircularLoaderTimerController();
    }

    show() {
        const total = Number(this.slug.split("-")[0])
        app.view.circularLoader.setLoadTotal(total);
        return app.view.circularLoader.show();
    }

    hide() {
        return app.view.circularLoader.hide();
    }

}