import UIListBase from "../../../tsunami/components/UIListBase";
import Point from "../../../tsunami/geom/Point";
import Scope from "../../../tsunami/Scope";
import { appConfig } from "../../AppConfig";
import { PixiListContainer } from "../PixiListContainer";
import HOILogosGridCell from "./HOILogosGridCell";

export default class HOILogosGrid extends UIListBase {

    constructor() {
        let element = new PixiListContainer();
        super(element);
        this.showChildrenDelay = 0.05;
        this.alsoShowChildren = true;
        this.gridSize = new Point(appConfig.width, appConfig.height - appConfig.titleHeight);
        this.gridSpan = new Point(6, 8);
        this.cellSize = new Point(this.gridSize.x / this.gridSpan.x, this.gridSize.y / this.gridSpan.y);

        this.provider = [
            { delay: 0, position: new Point(4, 0) },
            { delay: 2, position: new Point(3, 1) },
            { delay: 0, position: new Point(3, 2) },
            { delay: 1, position: new Point(5, 2) },
            { delay: 1, position: new Point(4, 3) },
            { delay: 1, position: new Point(1, 4) },
            { delay: 0, position: new Point(3, 4) },
            { delay: 1, position: new Point(2, 5) },
            { delay: 0, position: new Point(4, 5) },
            { delay: 0, position: new Point(0, 6) },
            { delay: 2, position: new Point(3, 6) },
            { delay: 2, position: new Point(2, 7) },
            { delay: 0, position: new Point(5, 7) },
        ]
    }

    destroyChild(element) {
        element.destroy();
    }

    _createElement(data, index, length) {
        const scope = new Scope(data, this.scope, index, length);
        const cell = new HOILogosGridCell(scope, this.cellSize);
        cell.x = data.position.x * this.cellSize.x;
        cell.y = data.position.y * this.cellSize.y;
        return cell;
    }

}