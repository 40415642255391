import PixiImageList from '../PixiImageList';
import NextUpAnim from './NextUpAnim';

export default class WeekGrid extends PixiImageList {

    constructor(gridSize, gridSpan, cellClass) {
        super(gridSize, gridSpan, cellClass);
        this.cellPositionOffset = { x: 0, y: -0.1 };

        this.nextUpAnimation = new NextUpAnim(this.cellSize);
    }

    get model() {
        return super.model;
    }

    set model(value) {
        super.model = value;
        if (this.nextUpAnimation.parent) this.nextUpAnimation.parent.removeChild(this.nextUpAnimation);
        if (!value) return;
        const selectedIndex = this.provider.indexOf(value);
        const nextIndex = selectedIndex + 1;
        const cell = this.children[nextIndex];
        if (cell) cell.addChild(this.nextUpAnimation);
    }

}