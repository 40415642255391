export class PixiWrapper {

    constructor(container) {
        this.container = container;
        this._translate = { x: 0, y: 0 };
        this._position = { x: 0, y: 0 };
    }

    get width() {
        return this._width;
    }

    set width(value) {
        this._width = value;
        this.updateX();
    }

    get height() {
        return this._height;
    }

    set height(value) {
        this._height = value;
        this.updateY();
    }


    get translateX() {
        return this._translate.x;
    }

    set translateX(value) {
        this._translate.x = value;
        this.updateX();
    }

    get translateY() {
        return this._translate.y;
    }

    set translateY(value) {
        this._translate.y = value;
        this.updateY();
    }

    get x() {
        return this._position.x;
    }

    set x(value) {
        this._position.x = value;
        this.updateX();
    }

    get y() {
        return this._position.y;
    }

    set y(value) {
        this._position.y = value;
        this.updateY();
    }

    updateX() {
        this.container.x = this.x + this.translateX * this.width;
    }

    updateY() {
        this.container.y = this.y + this.translateY * this.height;
    }

}