let canvas;

export function addBreaks(text, style, limit = Number.MAX_VALUE) {
    if (!canvas) canvas = document.createElement("canvas");
    canvas.width = style.wordWrapWidth;
    canvas.height = style.height || 500;
    const ctx = canvas.getContext("2d");
    ctx.font = `${style.fontWeight} ${style.fontSize}px ${style.fontFamily}`;
    const words = text.split(" ");
    if (words[words.length - 1].length < 2) {
        const lastWord = words.pop();
        words[words.length - 1] = words[words.length - 1] + " " + lastWord;
    }
    let textLines = "";
    let lineWords = "";
    words.forEach((word, i) => {
        let testLineWords = lineWords;
        if (testLineWords.length > 0) testLineWords += " ";
        testLineWords += word;
        const lineWidth = ctx.measureText(testLineWords).width;
        if (lineWidth >= style.wordWrapWidth) {
            if (textLines.length > 0) textLines += "\n";
            textLines += lineWords;
            lineWords = word;
        } else {
            if (lineWords.length > 0) lineWords += " ";
            lineWords += word;
        }
        if (i == words.length - 1) {
            if (textLines.length > 0) textLines += "\n";
            textLines += lineWords;
        }
    });
    const splittedLines = textLines.split("\n");
    while (splittedLines.length > limit) {
        splittedLines.pop();
    }
    return splittedLines.join("\n");
}