import * as PIXI from 'pixi.js';
import { appConfig } from "../../AppConfig";
import Easing from "../../../tsunami/animation/Easing";
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import CircleShape from "./CircleShape";
import DropViewImages from './DropViewImages';
import { app } from '../..';
import Rectangle from '../../../tsunami/geom/Rectangle';
import { awaitTimeout } from '../../../tsunami/await';

export default class DropViewImagesContainer extends PIXI.Container {

    constructor() {
        super();
        this.showTween = new Tween(0, 0.66, [new TweenProperty(this, "x", appConfig.width, 0, Easing.cubic.easeOut)]);
        this.hideTween = new Tween(0, 0.66, [new TweenProperty(this, "x", 0, -appConfig.width, Easing.cubic.easeIn)]);

        this.circleMask = new CircleShape(0xff0000);
        this.addChild(this.circleMask);
        this.mask = this.circleMask;

        this.hideMaskTween = new Tween(0, 0.25, [
            new TweenProperty(this.circleMask.scale, "x", 1, 0, Easing.cubic.easeIn),
            new TweenProperty(this.circleMask.scale, "y", 1, 0, Easing.cubic.easeIn),
        ]);

        this.images = new DropViewImages();
        this.addChild(this.images.element);
    }

    get model() {
        return this._model;
    }

    set model(value) {
        this._model = value;
        this.images.provider = value.images;
    }

    show() {
        let promise;
        // const posY = appConfig.width / -2 + this.area.height / 2 - appConfig.width * 0.025;
        const posY = appConfig.width / -2 + this.area.height / 2;
        if (app.model.monthSelectedRectangles) {
            const rectangle = app.model.monthSelectedRectangles[this.model.slug];
            rectangle.y = rectangle.height * -0.0025;

            app.model.monthSelectedRectangles = null;
            const cellRect = this.images.cellRect;
            const scale = cellRect.getScaleToFitRect(rectangle);
            const scaleTween = new Tween(0, 0.66, [
                new TweenProperty(this, "x", rectangle.x, 0, Easing.cubic.easeInOut),
                new TweenProperty(this, "y", rectangle.y, posY, Easing.cubic.easeInOut),
                new TweenProperty(this.scale, "x", scale, 1, Easing.cubic.easeInOut),
                new TweenProperty(this.scale, "y", scale, 1, Easing.cubic.easeInOut),
            ]);
            this.x = rectangle.x;
            this.y = rectangle.y;
            this.scale.x = scale;
            this.scale.y = scale;
            const delayPromise = awaitTimeout(1.25);
            promise = delayPromise.then(() => {
                return scaleTween.start();
            });
        } else {
            this.y = posY;
            this.showTween.start();
            promise = Promise.resolve();
        }
        return promise;
    }

    hide() {
        return this.hideTween.start();
    }

    showCircleMask() {
        this.circleMask.scale.x = this.circleMask.scale.y = 1;

        return Promise.resolve();
    }

    hideCircleMask() {
        return this.hideMaskTween.start();
    }

}
