import ArrayData from "../../tsunami/data/ArrayData";
import Data from "../../tsunami/data/Data";

export default class AppModel {

    constructor() {
        this.test = 5;
        this.drops = new ArrayData();
        this.drops.addEventListener(Data.CHANGE, () => {
            this.firstTwelveDrops = this.drops.slice(0, 12);
            // this.weekDrops = this.drops.slice(0, 3);
            this.weekDrops = this.drops.filter((drop) => {
                return drop.isDroppingThisWeek;
            }).slice(0, 12);
        });
        this.copydeck = {};
        this.cities = [];
        this.city = {
            language: "",
            marketplace: ""
        };
        this.inStockMode = false;
    }

    get nowDate() {
        return new Date();
    }
}
