import * as PIXI from 'pixi.js';
import { appConfig } from "../../AppConfig";

export default class TopTitleMask extends PIXI.Graphics {

    constructor() {
        super();
        this.pivot.x = 0;
        this.pivot.y = 0;
        this.lineStyle(0);
        this.beginFill(0xff0000, 1);
        this.moveTo(0, 0);
        this.lineTo(appConfig.width / 4, 0);
        this.lineTo(appConfig.width / 4, appConfig.height);
        this.lineTo(0, appConfig.height);
    }

}