import { PixiContainer } from "../PixiContainer";
import * as PIXI from 'pixi.js';
import { app } from "../..";
import Point from "../../../tsunami/geom/Point";
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Easing from "../../../tsunami/animation/Easing";

export default class CircleTextLine extends PixiContainer {

    constructor(text, radius) {
        super();
        this.text = text;
        this.radius = radius;

        this.animationFrame = this.animationFrame.bind(this);

        this.renderTexture = new PIXI.RenderTexture.create(2 * Math.PI * (radius), 50);

        let textString = "";
        for (let i = 0; i < 10; i++) {
            textString += text.toUpperCase() + " ";
        }

        this.circleText = new PIXI.Text(textString, {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 14,
            fill: 0xffffff,
            align: 'left'
        });

        let points = [];
        let anchorPoints = 100;
        for (let i = 0; i < anchorPoints; i++) {
            const point = Point.polar(radius, Math.PI * 2 / (anchorPoints - 1) * i);
            points.push(new PIXI.Point(point.x, point.y));
        }

        this.rope = new PIXI.SimpleRope(this.renderTexture, points);
        this.addChild(this.rope);

        this.ropeMask = new PIXI.Graphics();
        this.addChild(this.ropeMask);
        this.rope.mask = this.ropeMask;

        this.showTween = new Tween(0, 1, [new TweenProperty(this, "maskVisibility", 0, 1, Easing.cubic.easeInOut)]);
        this.hideTween = new Tween(0, 1, [new TweenProperty(this, "maskVisibility", 1, 0, Easing.cubic.easeInOut)]);
    }

    get maskVisibility() {
        return this._maskVisibility;
    }

    set maskVisibility(value) {
        this._maskVisibility = value;
        const maskRadius = this.radius + 30;
        this.ropeMask.clear();
        this.ropeMask.beginFill(0xff0000, 1);
        this.ropeMask.moveTo(0, 0);
        this.ropeMask.lineTo(0, -maskRadius);
        this.ropeMask.arc(0, 0, maskRadius, Math.PI / -2, Math.PI / -2 + Math.PI * 2 * value);
        this.ropeMask.lineTo(0, 0);
        this.ropeMask.endFill();
    }

    animationFrame() {
        this.rope.rotation += Math.PI / 180 * 0.4;
        this.ropeMask.rotation += Math.PI / 180 * 0.4;
        if (this.isVisible) window.requestAnimationFrame(this.animationFrame);
    }

    showDelayComplete() {
        app.view.renderer.render(this.circleText, this.renderTexture);
        this.rope.rotation = 0;
        this.ropeMask.rotation = 0;
        super.showDelayComplete();
        this.animationFrame();
        return this.showTween.start();
    }

    hideDelayComplete() {
        return this.hideTween.start();
    }

}