import * as PIXI from 'pixi.js';
import Rectangle from '../../../tsunami/geom/Rectangle';
import { addLeadingZero } from '../../../tsunami/utils/number';
import { appConfig } from '../../AppConfig';
import EncryptedText from '../EncryptedText';
import { PixiContainer } from '../PixiContainer';
import { addBreaks } from '../utils';

export default class DropViewDetailsContainer extends PixiContainer {

    constructor() {
        super();

        this._area = new Rectangle();

        this.margin = 40;


        this.titleText = new EncryptedText('This is a PixiJS text', {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 140,
            fill: 0xffffff,
            align: 'left',
            lineHeight: 140,
            wordWrap: false,
            wordWrapWidth: (appConfig.width - this.margin * 2)
        });
        this.addChild(this.titleText);
        this.titleText.x = this.margin;

        this.dateFontStyle = {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 140,
            fill: 0xffffff,
            align: 'left',
            wordWrap: true,
            wordWrapWidth: (appConfig.width - this.margin * 2)
        }

        this.dateText = new EncryptedText('This is a PixiJS text', this.dateFontStyle);
        this.dateText.x = this.margin;
        this.addChild(this.dateText);

        this.animationFrame = this.animationFrame.bind(this);
    }

    get area() {
        return this._area;
    }

    set area(value) {
        this._area = value;
    }

    get model() {
        return this._model;
    }

    set model(value) {
        this._model = value;

        this.updateDateText();

        this.titleText.model = addBreaks(this.model.fulltitle.toUpperCase(), this.titleText.style);
        // this.dateText.y = appConfig.width - 160 - this.margin;
        this.dateText.y = this.area.height - this.margin - this.dateFontStyle.fontSize;
    }

    showDelayComplete() {
        this.hits = 0;
        super.showDelayComplete();
        const promise1 = Promise.all([
            this.titleText.show(),
            this.dateText.show()
        ]);
        const promise2 = promise1.then(() => {
            if (this.model.isDroppingToday) {
                this.animationFrame();
            }
        });
        return promise2;
    }

    animationFrame() {
        if (this.hits % 3 == 0) {
            this.updateDateText();
        }
        this.hits++;
        if (this.isVisible) {
            requestAnimationFrame(this.animationFrame);
        }
    }

    updateDateText() {
        const time = this.model.getDropTime();
        this.dateText.model = time;
    }

    hideDelayComplete() {
        this.isVisible = false;
        const promises = [
            this.titleText.hide(),
            this.dateText.hide()
        ]
        return Promise.all(promises);
    }

}