import Branch from "../../../../tsunami/Branch";
import { app } from "../../..";

export default class CircularLoaderTimerController extends Branch {

    constructor() {
        super();
    }

    show() {
        const promise1 = app.view.circularLoader.progressLine.show();
        const duration = Number(this.slug.split("-")[0]);
        const promise2 = promise1.then(() => {
            return app.view.circularLoader.startTimer(duration);
        });
        return promise2;
    }

    hide() {

    }

}