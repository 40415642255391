import Tween from '../../tsunami/animation/Tween';
import TweenProperty from '../../tsunami/animation/TweenProperty';
import Easing from '../../tsunami/animation/Easing';
import { PixiWrapper } from './PixiWrapper';
import Rectangle from '../../tsunami/geom/Rectangle';
import * as PIXI from 'pixi.js';
import { appConfig } from '../AppConfig';
import { PixiContainer } from './PixiContainer';

export class PixiImageListCell extends PixiContainer {

    constructor(scope, size) {
        super();
        this.scope = scope;

        this.component = this;

        // this.bg = new PIXI.Graphics();
        // this.bg.beginFill(0xff0000, 1);
        // this.bg.drawRect(0, 0, size.x, size.y);
        // this.addChild(this.bg);

        this.cellRect = new Rectangle();

        this.spriteContainer = new PixiContainer();
        this.addChild(this.spriteContainer);
        this.spriteWrapper = new PixiWrapper(this.spriteContainer);

        let dropImage = scope.dropImage;
        this.imageRect = new Rectangle(0, 0, dropImage.image.naturalWidth, dropImage.image.naturalHeight);
        this.sprite = new PIXI.Sprite(dropImage.texture);
        this.spriteContainer.addChild(this.sprite);

        this.spriteMask = new PIXI.Graphics();
        this.addChild(this.spriteMask);
        this.mask = this.spriteMask;

        this.size = size;

        this.createTweens();
    }

    destroy() {
        super.destroy();
        // console.log("PixiImageListCell.destroy");
        let children = this.children.slice();
        children.forEach((child) => {
            this.removeChild(child);
        })
        this.scope = null;
        this.component = null;
        this.cellRect = null;
        this.spriteContainer = null;
        this.spriteWrapper = null;
        this.imageRect = null;
        this.sprite = null;
        this.spriteMask = null;
        this.mask = null;
        this.size = null;
        this.showTween = null;
        this.hideTween = null;
        this._model = null;
    }

    showDelayComplete() {
        super.showDelayComplete();
        return this.showTween.start();
    }

    hideDelayComplete() {
        return this.hideTween.start();
    }

    createTweens() {
        this.spriteWrapper.translateX = 1;
        this.showTween = new Tween(0, 0.33, [new TweenProperty(this.spriteWrapper, "translateX", 1, 0, Easing.cubic.easeOut)]);
        this.hideTween = new Tween(0, 0.33, [new TweenProperty(this.spriteWrapper, "translateX", 0, -1, Easing.quad.easeIn)]);
    }

    get size() {
        return this._size;
    }

    set size(value) {
        this._size = value;
        if (!value) return;

        // this.whiteSquare.clear();
        // this.whiteSquare.lineStyle(0);
        // this.whiteSquare.beginFill(0xffffff, 1);
        // this.whiteSquare.moveTo(0, 0);
        // this.whiteSquare.lineTo(value.x, 0);
        // this.whiteSquare.lineTo(value.x, value.y);
        // this.whiteSquare.lineTo(0, value.y);

        this.spriteMask.clear();
        this.spriteMask.lineStyle(0);
        this.spriteMask.beginFill(0xffffff, 1);
        this.spriteMask.moveTo(0, 0);
        this.spriteMask.lineTo(value.x, 0);
        this.spriteMask.lineTo(value.x, value.y);
        this.spriteMask.lineTo(0, value.y);

        let cellRect = new Rectangle(0, 0, value.x * appConfig.nikeImageFillScale, value.y * appConfig.nikeImageFillScale);
        let spriteScale = this.imageRect.getScaleToFitRect(cellRect);
        this.sprite.scale.x = spriteScale;
        this.sprite.scale.y = spriteScale;
        const offset = this.scope.positionOffset || { x: 0, y: 0 };
        this.sprite.x = value.x / 2 - this.sprite.width / 2 + this.sprite.width * offset.x;
        this.sprite.y = value.y / 2 - this.sprite.height / 2 + this.sprite.height * offset.y;

        this.spriteWrapper.width = value.x;
        this.spriteWrapper.height = value.y;
        this.spriteWrapper.translateX = this.spriteWrapper.translateX;
    }

}

export class PixiListCellFromTop extends PixiImageListCell {

    constructor(scope, size) {
        super(scope, size);
    }

    createTweens() {
        this.spriteWrapper.translateY = -1;
        this.showTween = new Tween(0, 0.33, [new TweenProperty(this.spriteWrapper, "translateY", -1, 0, Easing.cubic.easeOut)]);
        this.hideTween = new Tween(0, 0.33, [new TweenProperty(this.spriteWrapper, "translateY", 0, 1, Easing.quad.easeIn)]);
    }
}
