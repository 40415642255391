import Timeline from "../../tsunami/animation/Timeline";
import Point from "../../tsunami/geom/Point";
import { PixiContainer } from "./PixiContainer";
import * as PIXI from 'pixi.js';

export default class CellHighlights extends PixiContainer {

    constructor(gridSize, gridSpan, rowSize) {
        super();
        this.gridSize = gridSize;
        this.gridSpan = gridSpan;
        if (!rowSize) rowSize = new Point(1, 1);
        this.rowSize = rowSize;

        this.cellSize = new Point(gridSize.x / gridSpan.x, gridSize.y / gridSpan.y);

        this.showTimeline = new Timeline();
        this.hideTimeline = new Timeline();

        this.rowDelay = 0.3;

        this.rows = [];
        this.masks = [];

        for (let i = 0; i < gridSpan.y; i++) {
            const row = new PIXI.Graphics();
            row.lineStyle(0);
            row.beginFill(0xffffff, 1);
            row.drawRect(0, 0, gridSize.x * rowSize.x, this.cellSize.y);
            row.endFill();
            row.y = i * this.cellSize.y;
            this.addChild(row);
            this.rows.push(row);

            const rowMask = new PIXI.Graphics();
            this.masks.push(rowMask);
            this.addChild(rowMask);
            row.mask = rowMask;
        }

    }

    destroy() {
        this.rows.forEach((row) => {
            this.removeChild(row);
            row.mask = null;
            row.destroy();
        });
        this.masks.forEach((mask) => {
            this.removeChild(mask);
            mask.destroy();
        });
        this.showTimeline = null;
        this.hideTimeline = null;
        this.rows = null;
        this.masks = null;
    }


    redrawMasks(setup) {
        this.masks.forEach((mask, i) => {
            mask.clear();
            mask.lineStyle(0);
            mask.beginFill(0xff0000, 1);
            const rowData = setup[i];
            rowData.cells.forEach((drawCell, j) => {
                if (drawCell == 1) mask.drawRect(j * this.cellSize.x, i * this.cellSize.y, this.cellSize.x, this.cellSize.y);
            });
            mask.endFill();
        });
    }

    show() {
        this.redrawMasks(this.showSetup);
        return this.showTimeline.start();
    }

    hide() {
        this.redrawMasks(this.hideSetup);
        return this.hideTimeline.start();
    }

}