import EncryptedText from "../EncryptedText";
import { PixiImageListCell } from "../PixiImageListCell";
import { addBreaks } from "../utils";
import MonthCellDateText from "./MonthCellDateText";

export default class MonthCell extends PixiImageListCell {

    constructor(scope, size) {
        scope.positionOffset = { x: 0, y: -0.1 };
        super(scope, size);

        this.dateText = new MonthCellDateText('This is a PixiJS text', {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 14,
            fill: 0xffffff,
            align: 'center'
        });
        this.dateText.anchor.x = 0.5;
        this.dateText.x = size.x / 2;

        this.titleText = new EncryptedText('This is a PixiJS text', {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 14,
            fill: 0xffffff,
            align: 'center',
            wordWrap: false,
            wordWrapWidth: size.x - 50
        });
        this.titleText.anchor.x = 0.5;
        this.titleText.x = size.x / 2;
        // this.addChild(this.titleText);
        this.model = scope.data;
    }

    destroy() {
        super.destroy();
        this.dateText = null;
    }

    get model() {
        return this._model;
    }

    set model(value) {
        this._model = value;

        this.dateText.model = this.model.startEntryDate;
        this.dateText.y = this.size.y - this.dateText.height - 32;

        this.titleText.model = addBreaks(this.model.subtitle.toUpperCase() || this.model.title.toUpperCase(), this.titleText.style, 2);
        this.titleText.calculateTextHeight();
        this.titleText.y = this.size.y - 53;
        // this.titleText.y = this.size.y - 32 - this.titleText.encryptedTextHeight / 2;
    }

    showDelayComplete() {
        if (this.model.inStock) {
            this.selectedText = this.titleText;
        } else {
            this.selectedText = this.dateText;
        }
        const promise1 = super.showDelayComplete();
        const promise2 = promise1.then(() => {
            this.addChild(this.selectedText);
            Promise.all([
                this.selectedText.show(),
            ]);
        });
        return promise2;
    }

    hideDelayComplete() {
        const promise1 = this.selectedText.hide();
        const promise2 = promise1.then(() => {
            this.removeChild(this.selectedText);
            return super.hideDelayComplete();
        });
        return promise2;
    }

}
