import { PixiContainer } from "../PixiContainer";
import * as PIXI from 'pixi.js';
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Easing from "../../../tsunami/animation/Easing";
import Timeline from "../../../tsunami/animation/Timeline";

export default class HOITextNike extends PixiContainer {

    constructor(style) {
        super();
        this.rectMask = new PIXI.Graphics();
        this.rectMask.beginFill(0xff0000, 1);
        this.addChild(this.rectMask);
        this.mask = this.rectMask;

        this.text1 = new PIXI.Text("NIK", style);
        this.addChild(this.text1);

        this.text2 = new PIXI.Text("E", style);
        this.text2.x = this.text1.x + this.text1.width;
        this.addChild(this.text2);

        this.rectMask.drawRect(0, 0, this.text2.x + this.text2.width, this.text2.y + this.text2.height);

        this.showTimeline = new Timeline();
        this.showTimeline.add(new Tween(0, 1, [
            new TweenProperty(this.text1, "x", this.text1.x - this.text1.width, this.text1.x, Easing.cubic.easeOut),
            new TweenProperty(this.text2, "x", this.text1.x - this.text1.width - this.text2.width, this.text2.x, Easing.cubic.easeOut),
            new TweenProperty(this.text2, "alpha", 0, 1, Easing.linear.ease)
        ]));

        this.hideTimeline = new Timeline();
        this.hideTimeline.add(new Tween(0, 0.33, [
            new TweenProperty(this.text1, "x", this.text1.x, this.text1.x - this.text1.width, Easing.cubic.easeIn),
            new TweenProperty(this.text2, "x", this.text2.x, this.text1.x - this.text1.width - this.text2.width, Easing.cubic.easeIn),
            new TweenProperty(this.text2, "alpha", 1, 0, Easing.linear.ease)
        ]));

        this.text1.x = this.text1.x - this.text1.width;
        this.text2.x = this.text1.x + this.text1.width - this.text2.width;
    }

    showDelayComplete() {
        super.showDelayComplete();
        return this.showTimeline.start();
    }

    hideDelayComplete() {
        return this.hideTimeline.start();
    }

}