import * as PIXI from 'pixi.js';
import { appConfig } from "../../AppConfig";
import Easing from "../../../tsunami/animation/Easing";
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Point from '../../../tsunami/geom/Point';
import { app } from '../..';
import CircleShape from "./CircleShape";
import { awaitTimeout } from '../../../tsunami/await';

export default class DropViewWhiteCircle extends PIXI.Container {

    constructor() {
        super();
        this.whiteCircle = new CircleShape();
        this.addChild(this.whiteCircle);
        this.showTween = new Tween(0, 0.25, [
            new TweenProperty(this.scale, "x", 0, 1, Easing.cubic.easeOut),
            new TweenProperty(this.scale, "y", 0, 1, Easing.cubic.easeOut),
        ]);
        this.hideTween = new Tween(0, 0.25, [
            new TweenProperty(this.scale, "x", 1, 0, Easing.cubic.easeIn),
            new TweenProperty(this.scale, "y", 1, 0, Easing.cubic.easeIn),
        ]);

        this.animationFrame = this.animationFrame.bind(this);

        this.pivot.x = appConfig.width / 2;
        this.pivot.y = appConfig.width / 2;

        this.scale.x = 0;
        this.scale.y = 0;

        this.renderTexture = new PIXI.RenderTexture.create(2 * Math.PI * (appConfig.productCircleRadius - 30), 50);

        this.circleText = new PIXI.Text("", {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 30,
            fill: 0x000000,
            align: 'left'
        });

        var ropeContainer = new PIXI.Container();
        ropeContainer.position.x = (appConfig.width - appConfig.productCircleRadius * 2) / 2 + appConfig.productCircleRadius;
        ropeContainer.position.y = (appConfig.width - appConfig.productCircleRadius * 2) / 2 + appConfig.productCircleRadius;
        this.addChild(ropeContainer);

        let points = [];
        let anchorPoints = 100;
        for (let i = 0; i < anchorPoints; i++) {
            const point = Point.polar(appConfig.productCircleRadius - 30, Math.PI * 2 / (anchorPoints - 1) * i);
            points.push(new PIXI.Point(point.x, point.y));
        }

        // var textSprite = new PIXI.Sprite(this.renderTexture);
        // this.addChild(textSprite);

        this.rope = new PIXI.SimpleRope(this.renderTexture, points);

        ropeContainer.addChild(this.rope);

        // app.view.renderer.render(stage);
    }

    set model(value) {
        let textString = "";
        for (let i = 0; i < 10; i++) {
            textString += " " + value.fulltitle.toUpperCase();
        }
        this.circleText.text = textString;
        app.view.renderer.render(this.circleText, this.renderTexture);
    }

    animationFrame() {
        this.rope.rotation += Math.PI / 180 * 0.2;
        if (this.isVisible) window.requestAnimationFrame(this.animationFrame);
    }

    show() {
        this.y = this.area.height / 2;
        this.isVisible = true;
        this.animationFrame();
        const promise1 = this.showTween.start();
        const promise2 = promise1.then(() => { return awaitTimeout(0.5) });
        return promise2;
    }

    hide() {
        const promise1 = this.hideTween.start();
        const promise2 = promise1.then(() => {
            this.isVisible = false;
        });
        return promise2;
    }

}