import { PixiContainer } from "../PixiContainer"
import * as PIXI from 'pixi.js';
import { appConfig } from "../../AppConfig";
import { globalTextures } from "../GlobalAssets";
import Clock, { getClock } from "../../../tsunami/animation/Clock";
import { app } from "../..";

export default class NextDropAnim extends PixiContainer {

    constructor() {
        super();
		this.offset = Number(app.model.topBarY);
        this.bg = new PIXI.Graphics();
        this.bg.beginFill(0xffffff, 1);
        this.bg.drawRect(0, this.offset, appConfig.width, 20);
        this.addChild(this.bg);

        this.ticker = new PixiContainer();
        this.addChild(this.ticker);

        this.clockTick = this.clockTick.bind(this);
    }

    get model() {
        return this._model;
    }

    set model(value) {
        getClock().removeEventListener(Clock.TICK, this.clockTick);

        this.animationsFrames = 0;

        this._model = value;

        while (this.ticker.children.length > 0) {
            this.ticker.removeChild(this.ticker.children[0]);
        }
        let tickerWidth = 0;
        this.clips = [];
        while (tickerWidth < appConfig.width * 2) {
            let tickerClip = new NextDropAnimTickerClip(value);
            this.ticker.addChild(tickerClip);
            tickerClip.x = tickerWidth;
            tickerWidth += tickerClip.width + 50;
            if (this.clips.length == 0) this.tickerWidth = tickerWidth;
            this.clips.push(tickerClip);
        }

        getClock().addEventListener(Clock.TICK, this.clockTick);
    }

    clockTick() {
        let x = this.ticker.x - 0.6;
        if (x < -this.tickerWidth) {
            x += this.tickerWidth;
        }
        this.ticker.x = x;
        this.animationsFrames++;
        if (this.animationsFrames % 4 == 0) {
            this.clips.forEach((clip) => {
                clip.updateTime();
            });
        }
    }

}

export class NextDropAnimTickerClip extends PixiContainer {

    constructor(model) {
        super();
		this.offset = Number(app.model.topBarY);
        this.model = model;
        this.textStyle = {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 28,
            fill: 0x000000,
            align: 'left',
        }

        const textY = -8;

        this.textClip1 = new PIXI.Text(this.getText(), this.textStyle);
        this.textClip1.x = 0;
        this.textClip1.y = textY + this.offset;
        this.addChild(this.textClip1);

        // let title = "";
        // if (model) title = ;
        // this.textClip2 = new PIXI.Text(title, this.textStyle);
        // this.textClip2.x = this.textClip1.x + this.textClip1.width + 50;
        // this.textClip2.y = textY;
        // this.addChild(this.textClip2);

        this.nikeSprite = new PIXI.Sprite(globalTextures.nike.black);
        let endMargin = (this.model.inStock) ? 10 : 50;
        this.nikeSprite.x = this.textClip1.x + this.textClip1.width + endMargin;
		this.nikeSprite.y = this.offset;
        this.nikeSprite.scale.x = this.nikeSprite.scale.y = 0.2;
        this.addChild(this.nikeSprite);
    }

    getText() {
        let text;
        if (this.model.inStock) {
            text = "HOUSE OF INNOVATION" + "    " + app.model.city.name + "/" + app.model.city.index + "    " + "NIKE";
        } else {
            text = app.model.copydeck.next_drop_in.toUpperCase() + " " + this.model.getDropTime();
        }
        return text;
    }

    updateTime() {
        this.textClip1.text = this.getText();
    }

}
