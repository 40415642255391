import { loadImage } from "../../tsunami/load/loadImage";
import { loadVideoBlob } from "../../tsunami/load/loadVideoBlob";
import * as PIXI from 'pixi.js';
import { loadJSON } from "../../tsunami/load/loadJSON";
import {loadPixiSpriteSheet, setPixiSpriteSheet} from "../../tsunami/load/loadPixiSpriteSheet";
import { app } from "..";
import nikeLogoWhite from '../../../static/assets/images/nike-logo-white.svg';
import nikeLogoBlack from '../../../static/assets/images/nike-logo-black.svg';
import HOILogo from '../../../static/assets/images/HOI-logo.png';
import iconCropmark from '../../../static/assets/images/icon-cropmark.png';
import barsJson from '../../../static/assets/images/bars/bars.json';
import barsImg from '../../../static/assets/images/bars/bars.png';

export const globalAssets = {
    nike: {
        white: new Image(),
        black: new Image()
    },
    hoi: new Image(),
    cropmark: new Image(),
    cityLogo: new Image(),
}

export const globalTextures = {
    nike: {
        white: null,
        black: null
    },
    hoi: null,
    cropmark: null,
	cityLogo: null,
}

export const globalSpriteSheets = {
    bars: null
}

export function loadAssets() {
    let promises = [
        loadImage(nikeLogoWhite, globalAssets.nike.white),
        loadImage(nikeLogoBlack, globalAssets.nike.black),
        loadImage(HOILogo, globalAssets.hoi),
        loadImage(iconCropmark, globalAssets.cropmark),
    ];

	if (app.model.city.logo) {
		// promises.push(loadImage(`/assets/images/${app.model.city.logo}`, globalAssets.cityLogo));
	}

    const promise1 = Promise.all(promises);
    const promise2 = promise1.then((assets) => {
        globalTextures.nike.white = PIXI.Texture.from(globalAssets.nike.white);
        globalTextures.nike.black = PIXI.Texture.from(globalAssets.nike.black);
        globalTextures.hoi = PIXI.Texture.from(globalAssets.hoi);
        globalTextures.cropmark = PIXI.Texture.from(globalAssets.cropmark);

		if(app.model.city.logo) {
			globalTextures.cityLogo = PIXI.Texture.from(globalAssets.cityLogo);
		}
    });
    const sheet1 = setPixiSpriteSheet(barsImg, barsJson);
    const sheet2 = sheet1.then((sheet) => {
		console.log(sheet)
        globalSpriteSheets.bars = sheet;
    });

    return Promise.all([promise2, sheet2]).catch(error => { console.error(error.message) });
}
