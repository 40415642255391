import { PixiContainer } from "./PixiContainer";
import * as PIXI from 'pixi.js';
import Tween from "../../tsunami/animation/Tween";
import TweenProperty from "../../tsunami/animation/TweenProperty";
import Easing from "../../tsunami/animation/Easing";

export default class NikeSprite extends PixiContainer {

    constructor(texture) {
        super();

        this.nikeSprite = new PIXI.Sprite(texture);
        this.addChild(this.nikeSprite);

        const size = { x: 40, y: 15 };
        const angle = 5;
        this.spriteMask = new PIXI.Graphics();
        this.spriteMask.beginFill(0xff0000, 1);
        this.spriteMask.drawPolygon([0, 0, size.x, 0, size.x - angle, size.y, -angle, size.y, 0, 0]);
        this.spriteMask.alpha = 0.5;
        this.spriteMask.x = -size.x;
        this.addChild(this.spriteMask);
        this.nikeSprite.mask = this.spriteMask;

        this.showTween = new Tween(0, 0.5, [new TweenProperty(this.spriteMask, "x", -size.x, 0, Easing.cubic.easeOut)]);
        this.hideTween = new Tween(0, 0.5, [new TweenProperty(this.spriteMask, "x", 0, -size.x, Easing.cubic.easeIn)]);
    }

    showDelayComplete() {
        super.showDelayComplete();
        return this.showTween.start();
    }

    hideDelayComplete() {
        return this.hideTween.start();
    }

}
