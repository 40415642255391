import Branch from "../../tsunami/Branch";
import WeekController from "./WeekController";
import { MonthAllController } from "./MonthAllController";
import { MonthSelectedController } from "./MonthSelectedController";
import { Month12Controller } from "./Month12Controller";
import { app } from "..";

export default class MonthController extends Branch {

    constructor() {
        super();
        if (app.model.drops.value.length > 12) {
            this.branches.all = new MonthAllController();
        } else {
            this.branches.all = new Month12Controller();
        }
        this.branches.selected = new MonthSelectedController();
        this.branches.week = new WeekController();
        this.defaultChild = "all";
    }

    show() {
    }

    hide() {
    }

}
