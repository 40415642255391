import { PixiContainer } from "../PixiContainer";
import * as PIXI from 'pixi.js';
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Easing from "../../../tsunami/animation/Easing";
import Timeline from "../../../tsunami/animation/Timeline";
import { app } from "../..";
import { globalTextures } from "../GlobalAssets";

export default class HOILocationText extends PixiContainer {

    constructor(style) {
        super();
        this.rectMask = new PIXI.Graphics();
        this.rectMask.beginFill(0xff0000, 1);
        this.addChild(this.rectMask);
        this.mask = this.rectMask;

		if (app.model.city.logo) {
			this.storeLocationImage(style);
		} else {
			this.text1 = new PIXI.Text(app.model.city.name, style);
		}

        this.addChild(this.text1);

        this.text2 = new PIXI.Text("/", style);
        this.text2.x = this.text1.x + this.text1.width + 2;
        this.addChild(this.text2);

        this.text3 = new PIXI.Text(app.model.city.index.charAt(0), style);
        this.text3.x = this.text2.x + this.text2.width;
        this.addChild(this.text3);

        this.text4 = new PIXI.Text(app.model.city.index.charAt(1), style);
        this.text4.x = this.text3.x + this.text3.width;
        this.addChild(this.text4);

        this.text5 = new PIXI.Text(app.model.city.index.charAt(2), style);
        this.text5.x = this.text4.x + this.text4.width;
        this.addChild(this.text5);

        this.rectMask.drawRect(0, 0, this.text5.x + this.text5.width, this.text5.y + this.text5.height);


        this.showTimeline = new Timeline();
        this.showTimeline.add(new Tween(0, 1, [
            new TweenProperty(this.text1, "x", this.text1.x - this.text1.width, this.text1.x, Easing.cubic.easeOut),
            new TweenProperty(this.text2, "x", this.text1.x - this.text1.width - this.text2.width, this.text2.x, Easing.cubic.easeOut),
            new TweenProperty(this.text3, "x", this.text1.x - this.text1.width - this.text3.width, this.text3.x, Easing.cubic.easeOut),
            new TweenProperty(this.text4, "x", this.text1.x - this.text1.width - this.text4.width, this.text4.x, Easing.cubic.easeOut),
            new TweenProperty(this.text5, "x", this.text1.x - this.text1.width - this.text5.width, this.text5.x, Easing.cubic.easeOut),
            new TweenProperty(this.text2, "alpha", 0, 1, Easing.linear.ease),
            new TweenProperty(this.text3, "alpha", 0, 1, Easing.linear.ease),
            new TweenProperty(this.text4, "alpha", 0, 1, Easing.linear.ease),
            new TweenProperty(this.text5, "alpha", 0, 1, Easing.linear.ease),
        ]));

        this.hideTimeline = new Timeline();
        this.hideTimeline.add(new Tween(0, 0.33, [
            new TweenProperty(this.text1, "x", this.text1.x, this.text1.x - this.text1.width, Easing.cubic.easeIn),
            new TweenProperty(this.text2, "x", this.text2.x, this.text1.x - this.text1.width - this.text2.width, Easing.cubic.easeIn),
            new TweenProperty(this.text3, "x", this.text3.x, this.text1.x - this.text1.width - this.text3.width, Easing.cubic.easeIn),
            new TweenProperty(this.text4, "x", this.text4.x, this.text1.x - this.text1.width - this.text4.width, Easing.cubic.easeIn),
            new TweenProperty(this.text5, "x", this.text5.x, this.text1.x - this.text1.width - this.text5.width, Easing.cubic.easeIn),
            new TweenProperty(this.text2, "alpha", 1, 0, Easing.linear.ease),
            new TweenProperty(this.text3, "alpha", 1, 0, Easing.linear.ease),
            new TweenProperty(this.text4, "alpha", 1, 0, Easing.linear.ease),
            new TweenProperty(this.text5, "alpha", 1, 0, Easing.linear.ease),
        ]));

        this.text1.x = this.text1.x - this.text1.width;
        this.text2.x = this.text1.x + this.text1.width - this.text2.width;
        this.text3.x = this.text1.x + this.text1.width - this.text3.width;
        this.text4.x = this.text1.x + this.text1.width - this.text4.width;
        this.text5.x = this.text1.x + this.text1.width - this.text5.width;

    }

	storeLocationImage(style) {
		this.guide = new PIXI.Text(app.model.city.name, style);
		this.guide.alpha = 0;

		this.text1 = new PIXI.Sprite(globalTextures.cityLogo);
		const aspect = this.text1.height / this.text1.width;

		// makes it responsive but also does something strange to the image quality
		// const width = this.guide.width * .9;
		const width = 30;
		this.text1.width = width
		// this.text1.height = width * aspect;
		this.text1.height = 12;

		this.text1.y = this.text1.y + 4;
		this.text1.x = this.text1.x + 1;
	}

    showDelayComplete() {
        super.showDelayComplete();
        return this.showTimeline.start()
    }

    hideDelayComplete() {
        return this.hideTimeline.start();
    }

}
