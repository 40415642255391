import RouterWithHistory from "../../tsunami/RouterWithHistory";
import Branch from "../../tsunami/Branch";
import SNKRSController from "./SNKRSController";
import LoopController from "./LoopController";
import Router from "../../tsunami/Router";
import { app } from "..";

export default class AppController extends Branch {

    constructor() {
        super();
        // if (app.debug) {
        //     this.router = new RouterWithHistory(this, window.location.origin + window.location.pathname, "?");
        // } else {
        this.router = new Router(this);
        // }
        this.branches["*"] = new SNKRSController();
        this.defaultChild = "snkrs";
        this.branches.loop = new LoopController();

        app.view.nextDropAnim.model = app.model.drops.value[0];
    }

}
