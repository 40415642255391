import * as PIXI from 'pixi.js';
import { awaitTimeout } from '../../tsunami/await';

export class PixiContainer extends PIXI.Container {

    constructor() {
        super();

        this.showDelay = 0;
        this.hideDelay = 0;

        this.component = this;
    }

    destroy() {

    }

    show() {
        let promise1 = awaitTimeout(this.showDelay);
        let promise2 = promise1.then(() => {
            this.showPromises = [this.showDelayComplete()];
            return Promise.all(this.showPromises);
        });
        return promise2.then(this.showComplete.bind(this));
    }

    showDelayComplete() {
        this.isVisible = true;
    }

    showComplete() {
    }

    hide() {
        let promise1 = awaitTimeout(this.hideDelay);
        let promise2 = promise1.then(() => {
            this.hidePromises = [this.hideDelayComplete()];
            return Promise.all(this.hidePromises);
        });
        return promise2.then(this.hideComplete.bind(this));
    }

    hideDelayComplete() {
    }

    hideComplete() {
        this.isVisible = false;
    }
}
