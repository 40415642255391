import Scope from '../../tsunami/Scope';
import PixiList from './PixiList';

export default class PixiImageList extends PixiList {

    constructor(gridSize, gridSpan, cellClass, showChildrenDelay = 0.03, hideChildrenDelay = 0.03) {
        super(gridSize, gridSpan, cellClass, showChildrenDelay, hideChildrenDelay);
    }

    _createElement(data, index, length) {
        let scope = new Scope(data, this.scope, index, length);
        scope.dropImage = data.images[0];
        return new this.cellClass(scope, this.cellSize);
    }

}
