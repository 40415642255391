import Point from "../../../tsunami/geom/Point";
import { appConfig } from "../../AppConfig";
import { PixiContainer } from "../PixiContainer";
import * as PIXI from 'pixi.js';
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Easing from "../../../tsunami/animation/Easing";
import Timeline from "../../../tsunami/animation/Timeline";

export default class WeekLines extends PixiContainer {

    constructor(gridSize, gridSpan) {
        super();
        this.gridSize = gridSize;
        this.gridSpan = gridSpan;

        this.linesH = [];
        let lineSize = new Point(gridSize.x, appConfig.lineWidth);
        let gap = gridSize.y / gridSpan.y;
        for (let i = 0; i < gridSpan.y; i++) {
            const line = new PIXI.Graphics();
            line.lineStyle(0);
            line.beginFill(0xffffff, 1);
            line.moveTo(0, 0);
            line.lineTo(lineSize.x, 0);
            line.lineTo(lineSize.x, lineSize.y);
            line.lineTo(0, lineSize.y);
            line.x = lineSize.x;
            line.y = i * gap;
            line.pivot.x = lineSize.x;
            this.addChild(line);
            this.linesH.push(line);
        }

        this.linesV = [];
        lineSize = new Point(appConfig.lineWidth, gridSize.y);
        gap = gridSize.x / gridSpan.x;
        for (let i = 1; i < gridSpan.x; i++) {
            const line = new PIXI.Graphics();
            line.lineStyle(0);
            line.beginFill(0xffffff, 1);
            line.moveTo(0, 0);
            line.lineTo(lineSize.x, 0);
            line.lineTo(lineSize.x, lineSize.y);
            line.lineTo(0, lineSize.y);
            line.x = i * gap;
            line.y = lineSize.y;
            line.pivot.y = lineSize.y;
            this.addChild(line);
            this.linesV.push(line);
        }

        let mask = new PIXI.Graphics();
        mask.x = gridSize.x;
        mask.y = 0;
        mask.lineStyle(0);
        mask.beginFill(0xff0000, 1);
        mask.moveTo(0, 0);
        mask.lineTo(gridSize.x, 0);
        mask.lineTo(gridSize.x, gridSize.y);
        mask.lineTo(0, gridSize.y);
        this.mask = mask;
        this.addChild(mask);

        this.hideTimeline = new Timeline();
        this.linesV.forEach((line, i) => {
            this.hideTimeline.add(new Tween(i * 0.085, 0.5, [new TweenProperty(line.scale, "y", line.scale.y, 0, Easing.cubic.easeIn)]));
        });
        const linesHReversed = this.linesH.slice().reverse();
        linesHReversed.forEach((line, i) => {
            this.hideTimeline.add(new Tween(i * 0.085, 0.5, [new TweenProperty(line.scale, "x", line.scale.x, 0, Easing.cubic.easeIn)]));
        });
    }

    show() {
        this.resetAllLines();
        this.x = 0;
        return new Tween(0, 0.5, [new TweenProperty(this.mask, "x", this.gridSize.x, 0, Easing.cubic.easeOut)]).start();
    }

    hide() {
        return this.hideTimeline.start();
        // return new Tween(0, 0.5, [new TweenProperty(this.mask, "x", 0, this.gridSize.x, Easing.cubic.easeIn)]).start();
    }

    resetAllLines() {
        this.linesV.forEach((line) => {
            line.scale.y = 1;
        });
        this.linesH.forEach((line) => {
            line.scale.x = 1;
        });
    }

}