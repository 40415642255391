import { PixiContainer } from "../PixiContainer";
import * as PIXI from 'pixi.js';
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Easing from "../../../tsunami/animation/Easing";
import Timeline from "../../../tsunami/animation/Timeline";

export default class HOITextHouseOfInnovation extends PixiContainer {

    constructor(style) {
        super();
        this.rectMask = new PIXI.Graphics();
        this.rectMask.beginFill(0xffff00, 0.25);
        this.addChild(this.rectMask);
        this.mask = this.rectMask;

        this.text1 = new PIXI.Text("I", style);
        this.addChild(this.text1);

        this.text2 = new PIXI.Text("N", style);
        this.text2.x = this.text1.x + this.text1.width;
        this.addChild(this.text2);

        this.text3 = new PIXI.Text("N", style);
        this.text3.x = this.text2.x + this.text2.width;
        this.addChild(this.text3);

        this.text4 = new PIXI.Text("OVATION", style);
        this.text4.x = this.text3.x + this.text3.width;
        this.addChild(this.text4);

        this.rectMask.drawRect(0, 0, this.text4.x + this.text4.width, this.text4.y + this.text4.height);

        this.showTimeline = new Timeline();
        this.showTimeline.add(new Tween(0, 1, [
            new TweenProperty(this.rectMask, "x", this.rectMask.width, 0, Easing.cubic.easeOut),
            new TweenProperty(this.text1, "x", this.text4.x + this.text4.width - this.text1.width, this.text1.x, Easing.cubic.easeOut),
            new TweenProperty(this.text2, "x", this.text4.x + this.text4.width - this.text2.width, this.text2.x, Easing.cubic.easeOut),
            new TweenProperty(this.text3, "x", this.text4.x + this.text4.width - this.text3.width, this.text3.x, Easing.cubic.easeOut),
            new TweenProperty(this.text1, "alpha", 0, 1, Easing.linear.ease),
            new TweenProperty(this.text2, "alpha", 0, 1, Easing.linear.ease),
            new TweenProperty(this.text3, "alpha", 0, 1, Easing.linear.ease),
        ]));

        this.hideTimeline = new Timeline();
        this.hideTimeline.add(new Tween(0, 0.33, [
            new TweenProperty(this.rectMask, "x", 0, this.rectMask.width, Easing.cubic.easeIn),
            new TweenProperty(this.text1, "x", this.text1.x, this.text4.x + this.text4.width - this.text1.width, Easing.cubic.easeIn),
            new TweenProperty(this.text2, "x", this.text2.x, this.text4.x + this.text4.width - this.text2.width, Easing.cubic.easeIn),
            new TweenProperty(this.text3, "x", this.text3.x, this.text4.x + this.text4.width - this.text3.width, Easing.cubic.easeIn),
            new TweenProperty(this.text1, "alpha", 1, 0, Easing.linear.ease),
            new TweenProperty(this.text2, "alpha", 1, 0, Easing.linear.ease),
            new TweenProperty(this.text3, "alpha", 1, 0, Easing.linear.ease),
        ]));

        this.rectMask.x = this.rectMask.width;
    }

    showDelayComplete() {
        super.showDelayComplete();
        return this.showTimeline.start();
    }

    hideDelayComplete() {
        return this.hideTimeline.start();
    }

}
