import * as PIXI from 'pixi.js';
import Easing from '../../tsunami/animation/Easing';
import Timeline from '../../tsunami/animation/Timeline';
import Tween from '../../tsunami/animation/Tween';
import TweenProperty from '../../tsunami/animation/TweenProperty';

const upperCaseLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
const lowerCaseLetters = [];
upperCaseLetters.forEach((letter) => {
    lowerCaseLetters.push(letter.toLowerCase());
});
const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export default class EncryptedText extends PIXI.Text {

    constructor(text, style, debug = false) {
        super("", style);

        this.debug = debug;

        this._model = text;
        this._visibilityIndex = 0;
        this._encryptionIndex = 0;
        this.alpha = 0;
        this.encryptedTextHeight = 0;

        this.updateEncryptedText = this.updateEncryptedText.bind(this);

        this.showTimeline = new Timeline([
            new Tween(0, 0.66, [new TweenProperty(this, "visibilityIndex", 0, 1, Easing.quad.easeOut)], null, null, "visibilityIndex"),
            new Tween(0.66, 0.66, [new TweenProperty(this, "encryptionIndex", 0, 1, Easing.quad.easeOut)])
        ], 0, this.updateEncryptedText);

        this.hideTimeline = new Timeline([
            new Tween(0.5, 0.5, [new TweenProperty(this, "visibilityIndex", 1, 0, Easing.quad.easeIn)]),
            new Tween(0, 0.5, [new TweenProperty(this, "encryptionIndex", 1, 0, Easing.quad.easeIn)])
        ], 0, this.updateEncryptedText);
    }

    get model() {
        return this._model;
    }

    set model(value) {
        this._model = value;
        this.encrypt();
        this.updateEncryptedText();
    }

    calculateTextHeight() {
        this.text = this._model;
        this.encryptedTextHeight = this.height;
    }

    get visibilityIndex() {
        return this._visibilityIndex;
    }

    set visibilityIndex(value) {
        this._visibilityIndex = value;
        this.updateText();
    }

    get encryptionIndex() {
        return this._encryptionIndex;
    }

    set encryptionIndex(value) {
        this._encryptionIndex = value;
    }

    encrypt() {
        let string = "";
        for (let i = 0; i < this.model.length; i++) {
            const char = this.model.charAt(i);
            let newChar = char;
            if (upperCaseLetters.indexOf(char) != -1) {
                newChar = upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)];
            }
            if (lowerCaseLetters.indexOf(char) != -1) {
                newChar = lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)];
            }
            if (numbers.indexOf(char) != -1) {
                newChar = numbers[Math.floor(Math.random() * numbers.length)];
            }
            string += newChar;
        }
        this.encryptedText = string;
    }

    updateEncryptedText() {
        if (this.updateIndex % 3 == 0) {
            this.encrypt();
        }
        let string = this.model.substring(0, Math.round(this.encryptionIndex * this.model.length));
        string += this.encryptedText.substring(Math.round(this.encryptionIndex * this.model.length), this.model.length);
        let visibleString = string.substring(0, Math.round(this.visibilityIndex * this.model.length));
        this.text = visibleString;
        this.updateIndex++;
    }

    show() {
        this.updateIndex = 0;
        this.alpha = 1;
        this.encrypted = true;
        return this.showTimeline.start().then(() => {
            this.encrypted = false;
        });
    }

    hide() {
        this.encrypted = true;
        return this.hideTimeline.start().then(() => {
            this.alpha = 0;
        });

    }

}
