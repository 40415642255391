import { PixiContainer } from "../PixiContainer";
import Point from '../../../tsunami/geom/Point';
import { appConfig } from '../../AppConfig';
import WeekCellHighlights from "./WeekCellHighlights";
import { awaitTimeout } from "../../../tsunami/await";
import WeekGrid from "./WeekGrid";
import MonthCell from "../month/MonthCell";
import WeekLines from "./WeekLines";

export default class WeekView extends PixiContainer {

    constructor() {
        super();

        this.showDelay = 2;
    }

    set provider(value) {
        if (this.grid) {
            this.grid.destroy();
            this.cellHighlights.destroy();
        }
        const gridSize = new Point(appConfig.width, appConfig.height);
        const gridSpan = new Point(6, 2);
        gridSpan.x = 3;
        if (value.length > 3) gridSpan.x = 6;
        // gridSpan.x = Math.min(value.length, 6);
        // gridSpan.x = Math.max(gridSpan.x, 3);
        gridSpan.y = Math.ceil(value.length / gridSpan.x);

        // gridSize.y = appConfig.width / gridSpan.x * gridSpan.y;
        gridSize.y = 370;
        if (value.length > 3 && value.length < 7) gridSize.y = 369 / 2;

        this.cellHighlights = new WeekCellHighlights(gridSize, gridSpan);

        this.grid = new WeekGrid(gridSize, gridSpan, MonthCell);
        this.y = appConfig.height - gridSize.y;
        this.grid.provider = value;

        this.lines = new WeekLines(gridSize, gridSpan);
    }

    showDelayComplete() {
        super.showDelayComplete();
        this.addChild(this.lines);
        this.addChild(this.grid.element);
        const promise1 = this.grid.show();
        this.lines.show();
        const promise2 = awaitTimeout(0.25);
        const promise3 = promise2.then(() => {
            this.addChildAt(this.cellHighlights, 0);
            return this.cellHighlights.show();
        })
        promise3.then(() => {
            this.removeChild(this.cellHighlights);
        })
        return promise1;
    }

    hideDelayComplete() {
        this.lines.hide().then(() => {
            this.removeChild(this.lines);
        });
        const promise1 = this.grid.hide().then(() => {
            this.removeChild(this.grid.element);
        });
        return promise1;
    }

}
