import * as PIXI from 'pixi.js';
import Easing from "../../../tsunami/animation/Easing";
import Tween from "../../../tsunami/animation/Tween";
import TweenProperty from "../../../tsunami/animation/TweenProperty";
import Point from '../../../tsunami/geom/Point';
import { app } from '../..';
import { globalTextures } from '../GlobalAssets';
import { PixiContainer } from "../PixiContainer";

export const tickerSize = new Point(180, 19);

export default class NextUpAnim extends PixiContainer {

    constructor(cellSize) {
        super();
        this.cellSize = cellSize;

        this.y = 1;

        this.tickerMask = new PIXI.Graphics();
        this.tickerMask.beginFill(0xff0000, 1);
        this.tickerMask.drawRect(0, 0, cellSize.x, tickerSize.y);
        this.addChild(this.tickerMask);

        this.ticker = new PixiContainer();
        this.ticker.mask = this.tickerMask;
        this.addChild(this.ticker);

        this.bg = new PIXI.Graphics();
        this.bg.beginFill(0xffffff, 1);
        this.bg.drawRect(0, 0, cellSize.x, tickerSize.y);
        this.ticker.addChild(this.bg);

        this.message = new PixiContainer();
        this.ticker.addChild(this.message);

        this.tickers = [];

        this.tickerWidth = 0;
        let x = 0;
        for (let i = 0; i < 3; i++) {
            const ticker = new NextUpAnimTicker(cellSize);
            ticker.x = x;
            this.message.addChild(ticker);
            this.tickers.push(ticker);
            x += ticker.width;
            this.tickerWidth = Math.max(this.tickerWidth, ticker.width);
        }

        this.animate = this.animate.bind(this);

        this.ticker.y = -tickerSize.y - 1;

        this.showTween = new Tween(0, 0.33, [new TweenProperty(this.ticker, "y", -tickerSize.y - 1, 0, Easing.cubic.easeOut)]);
        this.hideTween = new Tween(0, 0.33, [new TweenProperty(this.ticker, "y", 0, -tickerSize.y - 1, Easing.cubic.easeIn)]);
    }

    showDelayComplete() {
        super.showDelayComplete();
        this.animate();
        return this.showTween.start();
    }

    animate() {
        let x = this.message.x - 0.6;
        if (x < -this.tickerWidth) x += this.tickerWidth;
        this.message.x = x;
        if (this.isVisible) window.requestAnimationFrame(this.animate);
    }

    hideDelayComplete() {
        return this.hideTween.start();
    }

}

export class NextUpAnimTicker extends PixiContainer {

    constructor() {
        super();
        this.textClip = new PIXI.Text(app.model.copydeck.next_up.toUpperCase(), {
            fontFamily: 'NeueHelveticaforHOI',
            fontSize: 28,
            fill: 0x000000,
            align: 'left',
        });
        this.textClip.x = 0;
        this.textClip.y = -8;
        this.addChild(this.textClip);

        this.nikeSprite = new PIXI.Sprite(globalTextures.nike.black);
        this.addChild(this.nikeSprite);
        this.nikeSprite.x = this.textClip.width + 4;
        this.nikeSprite.scale.x = this.nikeSprite.scale.y = 0.2;
    }

}
