import * as PIXI from 'pixi.js';
import Point from '../../../tsunami/geom/Point';
import { PixiContainer } from "../PixiContainer";

export default class CircleProgressLine extends PixiContainer {

    constructor(outerRadius, innerRadius, start) {
        super();

        let points;
        let point;


        // mask 
        this.maskShape = new PIXI.Graphics();
        this.maskShape.beginFill(0xff0000, 1);
        points = [];

        for (let i = 0; i <= 90; i++) {
            let rad = Math.PI / 180 * (i * 2);
            point = Point.polar(outerRadius + 5, start + Math.PI + rad);
            points.push(point.x, point.y);
        }
        this.maskShape.drawPolygon(points);
        this.addChild(this.maskShape);
        // this.maskShape.cacheAsBitmap = true;

        // shape
        this.shape = new PIXI.Graphics();
        // this.right.lineStyle(2, 0xffffff, 1);
        this.shape.beginFill(0xffffff, 1);

        points = [];

        for (let i = 0; i <= 90; i++) {
            let rad = Math.PI / 180 * (i * 2);
            point = Point.polar(outerRadius, start + rad);
            points.push(point.x, point.y);
        }
        point = Point.polar(innerRadius, start + Math.PI);
        points.push(point.x, point.y);

        for (let i = 0; i <= 90; i++) {
            let rad = Math.PI / 180 * (i * 2);
            point = Point.polar(innerRadius, start + Math.PI - rad);
            points.push(point.x, point.y);
        }
        point = Point.polar(innerRadius, start);
        points.push(point.x, point.y);

        this.shape.drawPolygon(points);
        this.addChild(this.shape);
        // this.shape.cacheAsBitmap = true;
        this.shape.mask = this.maskShape;
    }

}