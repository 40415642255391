import Easing from '../../../tsunami/animation/Easing';
import Tween from '../../../tsunami/animation/Tween';
import TweenProperty from '../../../tsunami/animation/TweenProperty';
import Point from '../../../tsunami/geom/Point';
import CellHighlights from '../CellHighlights';

export default class WeekCellHighlights extends CellHighlights {

    constructor(gridSize, gridSpan) {
        super(gridSize, gridSpan, new Point(0.33, 1));
        this.showSetup = [];
        for (let i = 0; i < gridSpan.y; i++) {
            const data = { cells: [], delay: i };
            for (let j = 0; j < gridSpan.x; j++) {
                const onOff = (Math.random() < 0.5) ? 0 : 1;
                data.cells.push(onOff);
            }
            this.showSetup.push(data);
        }

        const rowDelay = 0.3;

        this.rows.forEach((row, i) => {
            const showData = this.showSetup[i];
            this.showTimeline.add(new Tween(showData.delay / 1 * rowDelay, 0.33, [new TweenProperty(row, "x", -gridSize.x, gridSize.x, Easing.linear.ease)]));
        });
    }

    destroy() {
        this.showSetup = null;
        super.destroy();
    }

}
